import { Injectable } from '@angular/core';
import { Globals } from '../../global';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs';

@Injectable()
export class AccountDetailService {

    private accURL= this.globals.apiServerIP +'/accountDetails/';
    private accURL2= this.globals.apiServerIP +'/accountReport/';


    constructor(
      private httpClient: HttpClient,
      private globals: Globals,
    ){}

    getAccountList() {
      return this.httpClient.get(this.accURL)
      .pipe(
        map((response: any) => {
        return response[0].data;   
        }),
        catchError(this.handleError)
    );
    };

    getDetailedReport() {
      return this.httpClient.get(this.accURL2)
      .pipe(
        map((response: any) => {
        return response[0].data;   
        }),
        catchError(this.handleError)
    );
    };

    addAccountDetails(model: {}) {
        return this.httpClient.post(this.globals.apiServerIP +'/api/addAccount/',model)
        .pipe(
          map((response: any) => {
          return response;   
          }),
          catchError(this.handleError)
       )
    }

    updateAccountDetails(model: {}) {
        return this.httpClient.put(this.globals.apiServerIP +'/api/updateAccount/',model)
        .pipe(
          map((response: any) => {
          return response;   
          }),
          catchError(this.handleError)
      );
    }

    // Delete account details.
    deleteAccountDetails(accId: string) {
        var url = this.globals.apiServerIP + '/api/deleteAccount/' + accId ;
        return this.httpClient.delete(url)
        .pipe(
          map((response: any) => {
          return response[0].data;   
          }),
          catchError(this.handleError)
        );
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the details.', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
