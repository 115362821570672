import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empIdName'
})
export class EmpIdNamePipe implements PipeTransform {
  transform(empId: string, items: any[]): string {
    let empIdName : string = '';
    let resList: any[];

    if(!items) return empId;
    if(!empId) return '';
    empId = empId.toLowerCase();
    resList = items;
    resList = items.filter( it => {
       return it.Employee_Id.toLowerCase() == empId.toLowerCase();
    });


    if ( resList && resList.length ) {
      empIdName = resList[0].Employee_Name_Id;
    } else {
      empIdName = empId;
    }

    return empIdName;
  }
}
