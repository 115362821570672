import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectDetails } from "./company.project.resources";
//import 'rxjs/add/operator/toPromise';
import { Globals } from '../../global';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map } from 'rxjs';

@Injectable()
export class companyProjectService {

  private httpOptions: any = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
        private spinner: NgxSpinnerService
    ){}

    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
        this.spinner.hide();
        return Promise.reject(error.message || error);
    }

    getCompanyProjectDetails(): any {
        this.spinner.show();
        var projectDetailsUrl = this.globals.apiBaseURL + '/companyProjects/?token='+ this.globals.getLoginUserToken() ;
        return this.httpClient.get(projectDetailsUrl)
        .pipe(
          map((response:any) => {
             this.spinner.hide();//hide wait indicator
             return response[0].data;
          }),
          catchError(this.handleError)
       )
    };

    getProjectDetails(data:any): any {
        this.spinner.show();
        var detailsUrl = this.globals.apiBaseURL + '/projectDetails/'+data+'/?token='+ this.globals.getLoginUserToken() ;
        return this.httpClient.get( detailsUrl )
        .pipe(
          map((response:any) => {
             this.spinner.hide();//hide wait indicator
             return response[0].data;
          }),
          catchError(this.handleError)
       )
    };


    addProjectDetails(model: {}) {
        const headers = new Headers({'Content-Type': 'application/json'});
        return this.httpClient.post(
            this.globals.apiBaseURL + '/api/addProjectDetails/?token='+ this.globals.getLoginUserToken() ,
            model,
            this.httpOptions
        )
        .pipe(
          map((response:any) => {
             this.spinner.hide();//hide wait indicator
             return response;
          }),
          catchError(this.handleError)
       )
    }
}
