import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';

@Injectable()
export class personalDetailsReportsService {

    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
    ){}

    getPersoanlDetailsReports(data:any) {
        let params = new HttpParams();
		params = params.append('emp_id', data.empId);
		params = params.append('status', data.status);
		var urlGroups = this.globals.apiBaseURL + '/empPersonalDetails/';
		return this.httpClient.get(urlGroups, {params:params})
		.pipe(
			map((response: any) =>
			{
			return response[0].data;
			}),
			catchError(this.handleError)
		);
    };

    getActiveEmpList(){
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getActiveEmpList')
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
