import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';

import { catchError, map } from 'rxjs/operators';


@Injectable()
export class EmployeeDetailsByIdService {

    private url1 = this.globals.apiBaseURL + '/employeeProfile/';

    constructor(
        private httpClient:HttpClient,
        private globals: Globals,
    ){}

    getEmployeeDetailsById(id: any, des_Level: any) {

        let params = new HttpParams();
        params = params.append('employeeid', id);
        params = params.append('Des_Level', des_Level);
        return this.httpClient.get(this.url1 + id ,   {params: params})
        .pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
    }
    
    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the employee details by id.', error);
        return Promise.reject(error.message || error);
    }

}
