import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empStatus'
})
export class EmpActivePipe implements PipeTransform {

  transform(empId: string, items: any[]): any {
  
    let empIdStatus : string = '';
    let resList: any[];

    if(!items) return empId;
    if(!empId) return '';
    empId = empId.toLowerCase();

    resList = items.filter( it => {
      return it.Employee_Id.toLowerCase() == empId.toLowerCase();
    });

    if ( resList && resList.length ) {
      if(resList[0].Current_Status != 'Active'){
      empIdStatus = resList[0].Employee_Name_Id + " (" + resList[0].Current_Status + ")";
      }
      else{
        empIdStatus = resList[0].Employee_Name_Id ; 
      }
    } else {
        empIdStatus = empId;
    }

    return empIdStatus;
  }

}
