import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';

@Injectable()
export class HiringService {


	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	addHiringRequest(modelData: {}) {
		let url = this.globals.apiBaseURL + '/addHiringRequirement'
		return this.httpClient.post(url, modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getHiringRequests() {
		let url = this.globals.apiBaseURL + '/getHiringData';
		let params = new HttpParams();
		return this.httpClient.get(url,{params: params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	updateHiringRequest(modelData: {}) {
		let url = this.globals.apiBaseURL + '/updateHiringRequirement'
		return this.httpClient.put(url,modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getHiringHistoryRequests(data: any) {
		let url = this.globals.apiBaseURL + '/getHiringHistory';
		let params = new HttpParams();
		params = params.append('hiringId', data);
		return this.httpClient.get(url, { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};
	
	private handleError(error: any) {
		console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

}
