import { Component, OnInit } from '@angular/core';
import { CommonDataStreamService } from '../../services';
import { Globals } from '../../global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isActive = false;
  showMenu = '';
  username: string = '';
  role: number = 0;
  hideProfile: boolean = true;
  hideReport: boolean = true;
  hideHiring: boolean = true;
  hideAdmin: boolean = true;
  hideResource: boolean = true;
  hideRevenue: boolean = true;
  hideTravel: boolean = true;
  empString: any;
  showAwards: boolean = true;
  hideAppraisal : boolean = true;
  personalResAccList: string[] = [];
  travelpermissionList: any = [];
  accountsAccess: any = [];
  hiringsAccess: any = ['AAIN1093'];
  employeeDesignationLevel: any;

  constructor(
    private globals: Globals,
    private commonDataStreamService: CommonDataStreamService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.username = this.router.url.split('/')[2] ? this.router.url.split('/')[2] : this.globals.getLoginUsername();

    this.username = this.globals.getLoginUsername();
    this.role = this.globals.getLoginUserRole();
    this.empString = this.username.slice(0, 4);
    // this.pesonalResAccList = this.globals.getPesonalResAccList();
    this.personalResAccList = this.commonDataStreamService.dataStream.ampAccess;
    this.travelpermissionList = this.commonDataStreamService.dataStream.TravelModulePermission;
    this.employeeDesignationLevel = this.commonDataStreamService.dataStream['Employee_Designation_Level'][0];
    for (var i = 0; i < this.travelpermissionList.length; i++) {
      if (this.travelpermissionList[i].employee_id == this.username) {
        this.hideTravel = false;
        // console.log(this.hideTravel);
      }
    }

    if (this.empString == 'AINT' || this.empString == 'IINT' || this.empString == 'PINT') {
      this.showAwards = false;
    }


    switch (this.role) {
      case 1: this.hideProfile = false;
        this.hideReport = false;
        this.hideAdmin = false;
        this.hideResource = false;
        this.hideRevenue = false;
        this.hideAppraisal = false;
        break;
      case 2: this.hideProfile = false;
        this.hideReport = false;
        this.hideAdmin = false;
        this.hideResource = true;
        this.hideRevenue = true;
        this.hideAppraisal = false;
        break;
      case 4: this.hideProfile = false;
        this.hideReport = false;
        this.hideAdmin = true;
        this.hideResource = false;
        this.hideRevenue = false;
        this.hideAppraisal = false;
        break;
      case 8: this.hideProfile = false;
        this.hideReport = true;
        this.hideAdmin = true;
        this.hideResource = true;
        this.hideRevenue = true;

        //Employee of Delivery and not in SSG group
        if(this.employeeDesignationLevel && this.employeeDesignationLevel.Department_Id == '3' && this.employeeDesignationLevel.Group_Name != 'SSG'){
          this.hideAppraisal = false;
        }
        else{
          this.hideAppraisal = true;
        }

        /* For Sushant's and Arvind request to give resourcing access to a list of consultants. */
        if (this.personalResAccList.indexOf(this.username) !== -1) {
          this.hideResource = false;
        }

        break;
      case 16: this.hideProfile = false;
        this.hideReport = false;
        this.hideAdmin = false;
        this.hideResource = false;
        this.hideRevenue = false;
        break;
      default: this.hideProfile = true;
        this.hideReport = true;
        this.hideAdmin = true;
        this.hideResource = true;
        this.hideRevenue = true;
    }
    this.hideHiring = this.hideReport;
    if (this.hideReport != false) {
      this.hideReport = this.commonDataStreamService.dataStream.Employee_Designation_Level[0].Group_Name == "Finance" ? false : true;
    }
    if (this.accountsAccess.includes(this.username.toUpperCase())) {
      this.hideResource = false;
    }

    if (this.hiringsAccess.includes(this.username.toUpperCase())) {
      this.hideHiring = false;
    }

    if(this.username == 'AAIN0027'){
      this.hideReport = false;
    }
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

}
