import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Globals } from '../../global';



@Injectable()
export class ResourceCostService {

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	private handleError(error: any): Promise<any> {

		console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	addResourceCost(params: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/saveMonthlyResourceCostDetails/', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getResourceCostData(params: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/getResourceCostDetails/', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	getResourceCostTypes() {
		return this.httpClient.get(this.globals.apiServerIP + '/api/getResCostTypes/')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getResourceCostHandler() {
		return this.httpClient.get(this.globals.apiServerIP + '/api/getResCostHandler/')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};

	deleteResCostData(params: any) {
		return this.httpClient.put(this.globals.apiServerIP + '/api/deleteResourceCostDetails/', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	};


	updateResCostData(params: any) {
		return this.httpClient.put(this.globals.apiServerIP + '/api/updateResourceCostDetails/', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	};

	getLastDayMonth(monthstr: any) {

		var monthStrLast = monthstr;
		var lastDay;
		if (monthStrLast == "01" || monthStrLast == "03" || monthStrLast == "05" || monthStrLast == "07" || monthStrLast == "08" || monthStrLast == 10 || monthStrLast == 12) {
			lastDay = 31;
		}
		else if (monthStrLast == 2) {
			lastDay = 28;
		}
		else {
			lastDay = 30;
		}
		return lastDay;
	}

}
