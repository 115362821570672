import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ItTicketService {

  constructor(
		private httpClient: HttpClient,
		private globals: Globals,
  ) { }

  private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

  addRequest(params:any) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/addNewRequest/', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
	
  updateTicketsDetails(model: {}) {
		return this.httpClient.put(this.globals.apiServerIP +'/api/updatemMyrequest/',model)
		.pipe(
		  map((response: any) => {
		  return response;
		  }),
		  catchError(this.handleError)
	  );
	}
  getRequest(emp_id: string) {
		return this.httpClient.get(this.globals.apiServerIP + '/api/itSystemRequest/' + '?employeeid=' + emp_id)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}
  deleteRequest(Id :any) {
		var url = this.globals.apiBaseURL + '/api/deleteRequestDetails/';
		return this.httpClient.post(url, Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
	
  getApprovedRequest(data: any = {}){
	return this.httpClient.get(this.globals.apiServerIP + '/api/getRequestApproverData/', {params: data})
	.pipe(
		map((response: any) => {
			return response[0].data;
		}),
		catchError(this.handleError)
	);
  }
  getSupportData(){	
	return this.httpClient.get(this.globals.apiServerIP + '/api/getSupportData/')
	.pipe(
		map((response: any) => {
			return response[0].data;
		}),
		catchError(this.handleError)
	);
  }

  getRejectedData(){	
	return this.httpClient.get(this.globals.apiServerIP + '/api/getRejectedData')
	.pipe(
		map((response: any) => {
			return response[0].data;
		}),
		catchError(this.handleError)
	);
  }

  updateApprovals(model : any){
	var url = '';
		if(model.Status == 4){
			url = this.globals.apiServerIP +'/api/updateItApprovalManagers/';
		}
		else if(model.Status == 5){
			url = this.globals.apiServerIP +'/api/Assignapprovalrequest/';
		}
		else if(model.Status == 2){
			url = this.globals.apiServerIP +'/api/updateItTeamApproval/';
		}
		else if(model.Status == 1){
			url = this.globals.apiServerIP +'/api/updateItTeamApproval/';
		}
		else if (model.Status == 6) {
			url = this.globals.apiServerIP +'/api/updateItSupport/';
		}
		else if (model.Status == -2) {
			url = this.globals.apiServerIP +'/api/updateitsupportclose/';
		}
		else{
			url = this.globals.apiServerIP +'/api/updateItApproval/';
		}
		return this.httpClient.put(url, model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
}

}

