import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class IncentiveSimulatorService {

	private accURL = this.globals.apiServerIP + '/dataCaptureReport/';
	private accURL1 = this.globals.apiServerIP + '/incentivePayoutData/';
	private accURL2 = this.globals.apiServerIP + '/dataCaptureDetailReport/';
	private orgURL = this.globals.apiServerIP + '/generateOrganizationReport/';
	private orgDataURL = this.globals.apiServerIP + '/getOrganisationReport/';
	private publishOrgDataURL = this.globals.apiServerIP + '/publishOrganisationReport/';
	private generateDCURL = this.globals.apiServerIP + '/api/generateIncentiveAttribution/';

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	getIncDataCaptureData(incObj: any) {
		let params = new HttpParams();
		params = params.append('payoutPeriod', incObj.payoutPeriod);
		return this.httpClient.get(this.accURL,{ params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	incentivePayoutData() {
		return this.httpClient.get(this.accURL1)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	dataCaptureDetailReport(payoutPeriod: any, accountId: any, sowThread: any) {
		let params = new HttpParams();
		params = params.append('payoutPeriod', payoutPeriod);
		params = params.append('accountId', accountId);
		params = params.append('sowThread', sowThread);

		return this.httpClient.get(this.accURL2,{ params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	generateOrgData(orgObj: any) {
		return this.httpClient.post(this.orgURL,orgObj)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	};

	generateDCData(orgObj: any) {
		return this.httpClient.post(this.generateDCURL,orgObj)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	};



	getOrgData(orgObj: any) {
		let params = new HttpParams();
		params = params.append('payoutPeriod', orgObj.payoutPeriod);
		params = params.append('emp_id', orgObj.emp_id);

		return this.httpClient.get(this.orgDataURL,{ params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};

	publishOrgData(pubOrgObj: any) {
		return this.httpClient.post(this.publishOrgDataURL,pubOrgObj)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	};

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}
}