<div class="fixed-top header">
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <img src="assets/images/logo.svg" class="company-logo" alt="Affine Analytics" [routerLink]="['/dashboard']" />
            <div class="collapse navbar-collapse topbar" id="navbarSupportedContent">
                <h6 class="acis-text acis-text-size"> ACIS </h6>
                <form novalidate role="form" #f="ngForm" (ngSubmit)="f.valid && showProfile(f.value)" class="form-inline">
                    <input class="form-control mr-sm-2" type="text" placeholder="Search Employee Name / ID" list="employees" name="employeedetail" [(ngModel)]="employeedetail" #empdetail="ngModel" (input)="checkValidInput($any($event.target).value)" required />
                    <datalist id="employees" (ngModel)="employeeIdNameService.empIdNames">
                        <option *ngFor="let emp of employeeIdNameService.empIdNames"
                            value="{{ emp.Employee_Name }} ({{emp.Employee_Id}})" class="{{emp.Current_Status}}">
                            {{ emp.Employee_Name }} ({{emp.Employee_Id}})
                        </option>
                    </datalist>
                    <!-- The Below Data List is created here as a global element. It is being used at multiple components. Ex - admin/SOWApprover -->
                    <datalist id="active_employees" (ngModel)="employeeIdNameService.empIdNames_active">
                        <option *ngFor="let emp of employeeIdNameService.active_employees"
                            value="{{ emp.Employee_Name }} ({{emp.Employee_Id}})">
                            {{ emp.Employee_Name }} ({{emp.Employee_Id}})
                        </option>
                    </datalist>
                    <i class="fa fa-search header-search-icon" aria-hidden="true" title="Search" (click)="f.valid && showProfile(f.value)"></i>
                </form>

                <ul class="navbar-nav mb-2 mb-lg-0">
                    <li class="nav-item dropdown mt-2" *ngIf="ifApprover" title="SoW Notifications">
                        <a href="#" class="nav-link" id="notification1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bell notify-icon" aria-hidden="true" *ngIf="ifApprover"> 
                                <sup class="notify-count" *ngIf="notifySowLength > 0 ? true:false"> {{notifySowLength}} </sup> 
                            </i>
                            <i class="fa fa-bell" aria-hidden="true" *ngIf="ifNotApprover && !showInvReqs"></i>
                        </a>

                        <ul class="dropdown-menu dropdown-list notification-body-sow" aria-labelledby="notification1">
                            <li class="newSowsList sub-header disabled" *ngIf="newSows.length > 0">New SOWs ({{newSows.length}}) </li>
                            <li *ngFor="let notification of newSows; index as i" title="New SOW (click copies the name to clipboard)" (click)="copyToClipboard(notification.SOW_Name)">
                                <a class="dropdown-item" [routerLink]="['/sow']" [queryParams]="{id:notification.Account_Id, SOW_Id:notification.SOW_Id}" href="javascript:void(0)">
                                    {{ i + 1 }}. {{ notification.SOW_Name }}
                                </a>
                            </li>
                            <li class="updSowsList sub-header disabled" *ngIf="updSows.length > 0">Updated SOWs ({{updSows.length}})
                            </li>
                            <li *ngFor="let notification of updSows; index as i" title="Updated SOW (click copies the name to clipboard)" (click)="copyToClipboard(notification.SOW_Name)">
                                <a class="dropdown-item" [routerLink]="['/sow']" [queryParams]="{id:notification.Account_Id,SOW_Id:notification.SOW_Id}" href="javascript:void(0)">
                                    {{ i + 1 }}. {{ notification.SOW_Name }}
                                </a>
                            </li>
                            <li class="lvl1SowsList sub-header disabled" *ngIf="lvl1Sows.length > 0">Level 1 Approved SOWs ({{lvl1Sows.length}})</li>
                            <li *ngFor="let notification of lvl1Sows; index as i" title="Level 1 Approved SOW (click copies the name to clipboard)" (click)="copyToClipboard(notification.SOW_Name)">
                                <a class="dropdown-item" [routerLink]="['/sow']" [queryParams]="{id:notification.Account_Id,SOW_Id:notification.SOW_Id }" href="javascript:void(0)">
                                    {{ i + 1 }}. {{ notification.SOW_Name }}
                                </a>
                            </li>
                            <li class="lvl1SowsList sub-header disabled" *ngIf="lvl2Sows.length > 0">Level 2 Approved SOWs ({{lvl2Sows.length}})</li>
                            <li *ngFor="let notification of lvl2Sows; index as i" title="Level 2 Approved SOW (click copies the name to clipboard)" (click)="copyToClipboard(notification.SOW_Name)">
                                <a class="dropdown-item" [routerLink]="['/sow']" [queryParams]="{id:notification.Account_Id,SOW_Id:notification.SOW_Id }" href="javascript:void(0)">
                                    {{ i + 1 }}. {{ notification.SOW_Name }}
                                </a>
                            </li>
                            <li class="download sub-header" *ngIf="newSows.length > 0 || updSows.length > 0 || lvl1Sows.length > 0 || lvl2Sows.length > 0" title="Downloads the list of all SOWs pending for approval." (click)="downloadList()"><i class="fa fa-download" aria-hidden="true"></i>Download All SOW Alerts</li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown mt-2" *ngIf="showInvReqs" title="Invoicing Notifications">
                        <a href="#" class="nav-link" id="notification2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bell notify-icon" aria-hidden="true" *ngIf="showInvReqs">
                                <sup class="notify-count"> {{invReqs.length }} </sup>
                            </i>
                            <i class="fa fa-bell" aria-hidden="true" *ngIf="ifNotApprover && !showInvReqs"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-list notification-body-invoice" aria-labelledby="notification2">
                            <li class="lvl1SowsList sub-header disabled" *ngIf="invReqs.length > 0">Invoicing Notifications ({{invReqs.length}})</li>
                            <li *ngFor="let req of invReqs">
                                <a class="dropdown-item" [routerLink]="['/revenue/invoice',req.SOW_Id]" [queryParams]="{month:req.Revenue_Invoice_Month_Start | date: 'MM' }" href="javascript:void(0)"> <i class="fa fa-hand-o-right" aria-hidden="true"></i> {{ req.Revenue_Invoice_Month_Start | date: 'MMM y' }} : {{ req.SOW_Name }} </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link" id="userDropDown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img [src]="srcPath == null ? '#':srcPath" alt="" class="rounded-circle user-icon" crossorigin="anonymous"><strong class="user-text">{{employeeName}} </strong>
                        </a>
                        <ul class="dropdown-menu dropdown-list" aria-labelledby="userDropDown">
                            <li>
                                <a class="dropdown-item" [routerLink]="['/password']"><i class="fa fa-fw fa-key"></i> Change Password </a>
                            </li>
                            <li>
                                <a class="dropdown-item" [routerLink]="['/login']" (click)="onLoggedout()"><i class="fa fa-fw fa-power-off"></i> Log Out </a>
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>