
<router-outlet></router-outlet>
<ngx-spinner 
bdColor = "rgba(0, 0, 0, 0.9)" 
size = "medium" 
color = "#f05b5b" 
type = "timer" 
[fullScreen] = "true">
    <p style="color: white" > Loading... </p>
</ngx-spinner>











