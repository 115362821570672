export class EmployeeDetailsById {
    "Employee_Id": string;
    "Employee_Name": string;
    "Dob_Official": string;
    "Dob_Actual": string;
    "Gender": string;
    "Reporting_Manager": string;
    "Status": string;
    "Joining_Date": string;
    "Design_At_Joining": string;
    "Probation_Period": number;
    "Email_ID": string;
    "Contact_No": string;
    "Blood_Group": string;
    "EMContact_No": string;
    "Nationality": string;
    "Religion": string;
    "Lang_Known": string;
    "Permanent_Address": string;
    "Present_Address": string;
    "LinkedIN":string;
    "Skype":string;
    "Work_Location":string;
    "Team":string;
    "Skill_Sets":string;
}
