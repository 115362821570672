import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import {SkilltrackerService} from './shared/services/skilltracker/skilltracker.service';
import { NoopInterceptor } from './http-interceptors/auth-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserIdleModule } from 'angular-user-idle';
import { AuthGuard, AuthenticateService, Globals, CommonDataStreamService, FileUploadService,EmployeeIdNameService,EmployeeDetailsService, ServerService,EmployeeService,EmployeeLeaveService,CurrencyConfigService, HiringService,MsaService, SowService,PasswordService, AppraisalService, AccountDetailService, RevenueService, RevenueForecastAPIService,empExpReportsService, TransformerAwardsService, ProfitabilityService, TrackerService, IdeaService,ReimbursementService, TicketService, ItTicketService} from './shared';


import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IncentiveSimulatorService } from './shared/services/inc-simulator/incentive-simulator.service';
// optional, provides moment-style pipes for date formatting
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		MomentModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgxSpinnerModule,
		// Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
		// Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
		// and `ping` is 120 (2 minutes).
		UserIdleModule.forRoot({idle: 60*30, timeout: 15, ping: 12}),
		NgbModule
	],
	providers: [
	  AuthGuard,
	  EmployeeService,
	  EmployeeDetailsService,
	  EmployeeIdNameService,
	  EmployeeLeaveService,
	//   EmployeeDetailsByIdService,
	//   empEduReportsService,
	  empExpReportsService,
	  ServerService,
	  CurrencyConfigService,
	  AuthenticateService,
	  TrackerService,
	//   personalDetailsReportsService,
	//   educationalDetailsReportsService,
	  Globals,
	  CommonDataStreamService,
	//   companyProjectService,
	  PasswordService,
	  AccountDetailService,
	  MsaService,
	  SowService,
	  FileUploadService,
	ProfitabilityService,
	  RevenueService,
	  HiringService,
	  RevenueForecastAPIService,
	//   QAService,
	//   DownloadReportService,
	  IncentiveSimulatorService,
	  SkilltrackerService,
	  AppraisalService,
	  ItTicketService,
		TransformerAwardsService,
		IdeaService,
		TicketService ,
		ReimbursementService,
	  { provide: LocationStrategy, useClass: HashLocationStrategy },
	  { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
