import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class EmployeeService {

	private url1 = this.globals.apiBaseURL + '/api/applyResignation';
	private url2 = this.globals.apiBaseURL + '/api/getResAppUpdates';
	private url3 = this.globals.apiBaseURL + '/api/updateResignation';
	private url4 = this.globals.apiBaseURL + '/api/getResources';
	private url5 = this.globals.apiBaseURL + '/api/updateResources';
	private url6 = this.globals.apiBaseURL + '/api/getUtilizationData';
	private url7 = this.globals.apiBaseURL + '/api/getExResources';
	private url8 = this.globals.apiBaseURL + '/api/getEmpDetails';
	private url9 = this.globals.apiBaseURL + '/api/resourceHistory';

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	applyResignation(modelData: {}) {
		return this.httpClient.post(this.url1,modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateResignation(modelData: {}) {
		return this.httpClient.post(this.url3, modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	updateResignationForHR(modelData: {}) {
		return this.httpClient.post(this.globals.apiBaseURL + '/api/updateResignationForHR', modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getResignUpdates(empid: string) {
		return this.httpClient.get(
			this.url2 + '?employeeid=' + empid)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	getEmpDetails(empid: string) {
		return this.httpClient.get(this.url8 + '?employeeid=' + empid)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};


	getResourcesDetails(value: any | undefined, resourceType: string) {
		var getUrl = this.url4 + '?ResourceType=' + resourceType
		return this.httpClient.get(getUrl)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}



	getUtilizationDetails(Group:any ,Location:any ) {
		let params = new HttpParams();
		params = params.append('Group', Group);
		params = params.append('Location', Location);
		return this.httpClient.get(this.url6, { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;

				}),
				catchError(this.handleError)

			);

	}

	updateResourcesDetails(modelData: {}) {
		return this.httpClient.post(this.url5,modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	onSelecHistorytDate(modelData:any) {
		let historyURL = this.url9 + '?fromDate=' + modelData.fromDate + '&empId=' + modelData.Employee_Id;
        return this.httpClient.get(historyURL)
            .pipe(
                map((response: any) => {
                    return response[0].data;
                }),
                catchError(this.handleError)
            );
	}

	getExResourcesDetails() {
		return this.httpClient.get(this.url7)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the list of employees', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

}
