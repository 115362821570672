import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Globals } from "../../global";

@Injectable()
export class CurrencyConfigService {
	private baseURL = this.globals.apiBaseURL + "/";

	dataStream: any;

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) {}

	/* get currency */
	getCurrency() {
		var getCurrencyUrl = this.baseURL + "api/getCurrency/";
		return this.httpClient.get(getCurrencyUrl)
        .pipe(
            map((response:any) => {
				return response[0].data;
            }),
            catchError(this.handleError)
        );
	}

    /* Fiscal Year List (Used in Tracker->currency-conversion) */
    getFiscalYearList(){
		return this.httpClient.get(this.globals.apiServerIP + '/api/getFiscalYearList/')
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

    /* Get Currency Conversion Data */
    getCurrConfigData(params:any) {
		return this.httpClient .post(this.globals.apiServerIP + "/api/getCurrencyConfigData/", params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	private handleError(error: any): Promise<any> {
		console.error(
			"An error occurred while fetching the employee details.",
			error
		); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	/* add currency */
	addCurrency(modelData: {}) {
		var addCurrencyUrl = this.baseURL + "api/addCurrency/";
		return this.httpClient.post(addCurrencyUrl, modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

    /* Add Currency Conversion Data */
    addcurrConfigDetails(model: {}) {
		return this.httpClient
			.post(this.globals.apiServerIP +"/api/addCurrencyConfigData",model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

}
