import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class EmployeeIdNameService {

	private url1 = this.globals.apiBaseURL + '/employeeIdName';
	private notifyURL = this.globals.apiServerIP + '/api/notifySOW';
	public empIdNames: any;
	public empIdNames_active: any;
	active_employees: any;

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	getEmployeeIdName() {
		return this.httpClient.get(this.url1)
			.pipe(
				map((response: any) => {
					this.empIdNames = response[0].data[0].data;
					this.active_employees = [];
					this.active_employees = response[0].data[0].data.filter((obj: { Current_Status: string; }) => obj.Current_Status === 'Active' || obj.Current_Status === 'Notice');
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};

	getEmployeeNameId(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId.toUpperCase();
			});
			return refObj.length ? refObj[0].Employee_Name_Id : "";
		}

	}

	getEmployeeName(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Employee_Name : "";
		}

	}
	getEmpGroupName(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Group_Name: "";
		}

	}

	getEmpReportingManger(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Reporting_Manager : "";
		}

	}

	getEmpHRSPOC(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].HR_SPOC : "";
		}

	}

	getEmpJoiningDate(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Joining_Date : "";
		}

	}
	getEmpOfficialEmail(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].OfficialEmail_Id: "";
		}

	}
	getEmpPersonalEmailId(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].PersonalEmail_Id: "";
		}
	}
	getEmpContactNo(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Contact_No: "";
		}
	}
	getEmpEmergencyContactName(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Emergency_Contact_Name: "";
		}
	}
	getEmpEmergencyContactNo(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Emergency_Contact_No: "";
		}
	}
	

	getEmpInititalJoiningDate(empId: string) {
		if (this.empIdNames === undefined) {
			return "";
		} else {
			var refObj = this.empIdNames.filter((obj: { Employee_Id: string; }) => {
				return obj.Employee_Id == empId;
			});
			return refObj.length ? refObj[0].Initial_Joining_Date : "";
		}

	}

	getNotifySOW() {
		return this.httpClient.get(this.notifyURL)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

}
