import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameFilter'
})
export class FileNamePipe implements PipeTransform {
  transform(fileName: any): string {

    let fileModified: string = '';

    if(fileName && fileName.includes('__'))
    {
      fileName = fileName.split('__');
      if(fileName.length > 1)
      {
        fileName.shift();
      }
      fileName = fileName.join('__');
    }
    fileModified = fileName;
    return fileModified;
  }
}
