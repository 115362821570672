import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonDataStreamService } from '../../services';

@Component({
	selector: 'app-aa-designation',
	templateUrl: './aa-designation.component.html',
	styleUrls: ['./aa-designation.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AaDesignationComponent),
			multi: true
		}
	],
})
export class AaDesignationComponent implements OnChanges, ControlValueAccessor {

	@Input('options') options: any = {
		defDpt: '',
		defGrp: '',
		defSubGrp: '',
		defLvl: '',
		defDsg: ''
	};
	@Input('editable') editable = false;

	showPopup: boolean = false;

	allDesgData: any = [];
	oldDesgData: any = [];
	newDesgData: any = [];

	Departments: any = [];
	Groups: any = [];
	Sub_Groups: any = [];
	Levels: any = [];
	Designations: any = [];

	defDesgData: any = {};
	defDpt: any = "";
	defGrp: any = "";
	defSubGrp: any = "";
	defLvl: any = "";
	defDsg: any = "";

	constructor(
		private cds: CommonDataStreamService
	) {
		if (this.cds.dataStream && this.cds.dataStream.designations) {
			this.allDesgData = this.cds.dataStream.designations;
			this.oldDesgData = this.allDesgData.filter((obj: any) => obj.Group_Id == null);
			this.newDesgData = this.allDesgData.filter((obj: any) => obj.Group_Id);
			this.Departments = this.getUniqueList(this.newDesgData, ["Department_Id"]);
		} else {
			console.log("No designations from the common datastream.");
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.loadDefauts();
	}

	loadDefauts() {
		if (this.value) {
			this.defDesgData = this.allDesgData.find((obj: any) => obj.Designation_Id == this.value);
			this.defDpt = this.defDesgData.Department_Id;
			this.loadChildren([['Department_Id', this.defDpt]], 'Group');
			this.defGrp = this.defDesgData.Group_Id;
			this.loadChildren([['Group_Id', this.defGrp], ['Department_Id', this.defDpt]], 'Sub_Group');
			this.defSubGrp = this.defDesgData.Sub_Group_Id;
			this.loadChildren([['Sub_Group_Id', this.defSubGrp], ['Department_Id', this.defDpt], ['Group_Id', this.defGrp]], 'Level');
			this.defLvl = this.defDesgData.Level_Id;
			this.loadChildren([['Level_Id', this.defLvl], ['Department_Id', this.defDpt], ['Group_Id', this.defGrp], ['Sub_Group_Id', this.defSubGrp]], 'Designation');
			this.defDsg = this.value;
		}
		else if (this.options) {
			this.defDpt = this.options.defDpt;
			this.loadChildren([['Department_Id', this.defDpt]], 'Group');
			this.defGrp = this.options.defGrp;
			this.loadChildren([['Group_Id', this.defGrp], ['Department_Id', this.defDpt]], 'Sub_Group');
			this.defSubGrp = this.options.defSubGrp;
			this.loadChildren([['Sub_Group_Id', this.defSubGrp], ['Department_Id', this.defDpt], ['Group_Id', this.defGrp]], 'Level');
			this.defLvl = this.options.defLvl;
			this.loadChildren([['Level_Id', this.defLvl], ['Department_Id', this.defDpt], ['Group_Id', this.defGrp], ['Sub_Group_Id', this.defSubGrp]], 'Designation');
			this.defDsg = this.options.defDsg;
		}
	}

	getFilteredList(dataArr: any, keyValsArr: any[]) {
		let filter, result = [];
		result = this.newDesgData.filter((d: any) => {
			filter = true;
			keyValsArr.forEach(kv => {
				if (d[kv[0]] != kv[1]) {
					filter = false;
				}
			});
			return filter;
		});
		return result;
	}

	getUniqueList(dataArr: any, keysArr: any) {

		let result: any = [], tmp;

		dataArr.forEach((d: any) => {

			if (result.length === 0 || result.find((r: any) => {

				tmp = true;

				keysArr.forEach((k: any) => {
					if (r[k] !== d[k]) {
						tmp = false;
					}
				});

				return tmp;
			}) === undefined) {
				result.push(d);
			}
		});

		return result;
	}

	loadChildren(parKeyVals: any, child: any) {
		switch (child) {
			case 'Group':
				this.defGrp = "";
				this['Sub_Groups'] = [];
				this['Groups'] = this.getUniqueList(this.getFilteredList(this.newDesgData, parKeyVals), parKeyVals.map((kv: any) => kv[0]).concat(child + '_Id'));
				break;

			case 'Sub_Group':
				this.defSubGrp = "";
				this['Levels'] = [];
				this['Sub_Groups'] = this.getUniqueList(this.getFilteredList(this.newDesgData, parKeyVals), parKeyVals.map((kv: any) => kv[0]).concat(child + '_Id'));
				break;

			case 'Level':
				this.defLvl = "";
				this['Designations'] = [];
				this['Levels'] = this.getUniqueList(this.getFilteredList(this.newDesgData, parKeyVals), parKeyVals.map((kv: any) => kv[0]).concat(child + '_Id'));
				break;

			case 'Designation':
				this.defDsg = "";
				this['Designations'] = this.getUniqueList(this.getFilteredList(this.newDesgData, parKeyVals), parKeyVals.map((kv: any) => kv[0]).concat(child + '_Id'));
				break;
		}
	}

	toggleDesgEdit() {
		this.showPopup = !this.showPopup;
		this.loadDefauts();
	}

	handleClick(target: any) {
		if (target.className.indexOf('aa-desg-form') !== -1) {
			this.toggleDesgEdit();
		}
	}

	emitNewDesignationDetails(form: any) {
		if (form && form.valid) {
			this.writeValue(this.defDsg);
			this.toggleDesgEdit();
		}
	}


	@Input() name: string = '';
	@Input('value') val: string = '';
	// Both onChange and onTouched are functions
	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.val;
	}

	set value(val) {
		this.val = val;
		this.onChange(val);
		this.onTouched();
	}

	// We implement this method to keep a reference to the onChange
	// callback function passed by the forms API
	registerOnChange(fn: any) {
		this.onChange = fn;
	}
	// We implement this method to keep a reference to the onTouched
	//callback function passed by the forms API
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: any) {
		if (value) {
			this.value = value;
		}
	}

}
