import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';
import { param } from 'jquery';

@Injectable()
export class TicketService {
    private baseURL = this.globals.apiBaseURL + '/';
    TicketDataStream : any;

  constructor(
		private httpClient: HttpClient,
		private globals: Globals,
  ) { }

 
    commonDataTicket() {
        var url1 = this.baseURL + 'TicketCommanData';
        return this.httpClient.get(url1)
        .pipe(
            map((response: any) => {
                this.TicketDataStream = response[0].data[0].data;
                /* To avoid getting the list again from API. We can just filter the non PIP types for the utilization. */
                // var resTypes = this.dataStream.resourceTypes.slice(0);
                // this.dataStream.utilizationresourceType = resTypes.filter((obj: { Type: string; }) => obj.Type != 'PIP');
                return response[0].data;
            }),
            catchError(this.handleError)
        );
    }

     private handleError(error: any): Promise<any> {
    console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
    return Promise.reject(error.message || error);
}

    
    // getTicketStatus() {
    //     return this.httpClient.get(this.globals.apiServerIP + '/api/getTicketStatus/')
    //     .pipe(
    //         map((response: any) => {
    //             return response[0].data;
    //         }),
    //         catchError(this.handleError)
    //     );
    // }
    // getServiceType(){
    //     return this.httpClient.get(this.globals.apiServerIP + '/api/getTicketServiceType/')
    //     .pipe(
    //         map((response: any) => {
    //             return response[0].data;
    //         }),
    //         catchError(this.handleError)
    //     );
    // }
}