export class ProjectDetails {
    'Employee_Id': string;
    'Employee_Name': string;
    'Organst_Name': string;
    'Joining_Date': string;
    'Leaving_Date': string;
    'Year_Of_Experic': string;
    'Previous_Destination': string;
    'PF_AC_No': string;
    'Source_Of_Hire': string;
}
