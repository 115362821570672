import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CDSDataResolver } from './shared';

const routes: Routes = [
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard], resolve: {cds: CDSDataResolver}},
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
    { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m =>m.SignupModule)},
    { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m =>m.ForgotPasswordModule)},
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m =>m.NotFoundModule)},
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{enableTracing: false})],
    exports: [RouterModule],
    providers: [CDSDataResolver]
})
export class AppRoutingModule { }
