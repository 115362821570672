import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map, pipe } from 'rxjs';

@Injectable()
export class empExpReportsService {



	constructor(
		private httpClient: HttpClient,
		private globals: Globals
	) { }

	getEmployeeExpReports(data: { Employee_Status: string; }) {

		var firstUrl = this.globals.apiBaseURL + '/empExperieceCount/?status=' + data.Employee_Status;
		return this.httpClient.get(firstUrl)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};

	getEmployeeDesignationCount(model: {}) {
		return this.httpClient.post(this.globals.apiBaseURL + '/designationCounts/',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeDesignationDetails(model: {}) {
		return this.httpClient.post(this.globals.apiBaseURL + '/designationDetails/',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeDiversityRatio(model: {}) {
		return this.httpClient.post(this.globals.apiBaseURL + '/diversityRatio/',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeSourceOfHire(model: {}) {
		return this.httpClient.post(this.globals.apiBaseURL + '/sourceOfHire/',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeContactDetails(data: { Employee_Status: string; }) {
		var contactDetailsUrl = this.globals.apiBaseURL + '/reportsEmpContactDetails/?status=' + data.Employee_Status ;
		return this.httpClient.get(contactDetailsUrl)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};

	getAttritionDetails(data: { Attrition_Type: string; From: string; To: string; }) {
		var attritionDetailsUrl = this.globals.apiBaseURL + '/api/getAttritionData/?Type=' + data.Attrition_Type + '&From=' + data.From + '&To=' + data.To;
		return this.httpClient.get(attritionDetailsUrl)

			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	};

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}
}
