import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Globals } from '../shared/global';
import { NgxSpinnerService } from "ngx-spinner";


@Injectable()
export class NoopInterceptor implements HttpInterceptor {

	constructor(
		private globals: Globals,
		private spinner: NgxSpinnerService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.spinner.show()
		
		if(this.globals.getLoginUserToken() != ''){
			req = req.clone({ setHeaders : {'Authorization': this.globals.getLoginUserToken(),'Content-Type': 'application/json' } });
		}
		else{
			req = req.clone({ setHeaders : {'Content-Type': 'application/json' } });
		}
		// Process all the responses here.
		if (req.method === 'POST' || req.method === 'PUT') {
			
			// Encryption goes here
			// var encBody = this.globals.getEncryptData( req.body );
            // console.log("xxxxxxxxxxxxxx");
			// console.log(req);
			var encBody = req.body;
			const newReq = req.clone({ body: { data: encBody } });
			return next.handle(newReq).pipe(map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.spinner.hide()
					return event.clone({ body: event.body[0].data });
				}
				return event;
			}));

		} else {
			return next.handle(req).pipe(map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.spinner.hide()
					return event.clone({ body: event.body });
				}
					return event;
				
			}));
		}

	}

}
