import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class TrackerService {

	private getEmpdetailsURL = this.globals.apiServerIP + '/api/getEmpResourcingDetails/';
	private getholidayQA = this.globals.apiServerIP + '/api/getHolidays/';


	constructor(
		private httpClient: HttpClient,
		private globals: Globals,

	) { }

	private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	getEmpdetails(data: any) {
		let params = new HttpParams();
		params = params.append('employeeid', data.user);
		params = params.append('designation_level', data.designation_level);
		params = params.append('holidayFlag', data.holidayFlag);
		params = params.append('startdate', data.startdate);
		params = params.append('enddate', data.enddate);
		params = params.append('gracePeriodStartDate', data.gracePeriodStartDate);
		params = params.append('gracePeriodEndDate', data.gracePeriodEndDate);

		return this.httpClient.get(this.getEmpdetailsURL, { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getHolidays() {
		let params = new HttpParams();
		return this.httpClient.get(this.getholidayQA, { params: params })
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getTrainingList(){
		return this.httpClient.get(this.globals.apiServerIP +'/api/getTrainingList')
			.pipe(
				map((response: any) => {
					return response[0].data[0];
				}),
				catchError(this.handleError)
			);
	}

	addTrainingList(model:any) {
		return this.httpClient.post(this.globals.apiServerIP + '/addTraining/', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getTrainingData(empid: any) {
		let params = new HttpParams();
		params = params.append('Id', empid);
		return this.httpClient.get(this.globals.apiServerIP + '/getTrainingData', {params: params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	deleteTraining(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/deleteTrainingsDetails', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateTrainingDetails(model: {}) {
		return this.httpClient.put(this.globals.apiServerIP +'/api/updateTraining/',model)
		.pipe(
		  map((response: any) => {
		  return response;
		  }),
		  catchError(this.handleError)
	  );
	}

	getTrainingApproverData(data : any = {}) {
		return this.httpClient.get(this.globals.apiServerIP + '/getTrainingApproverData/', {params: data})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}
	getTrainerList(Course_Id:any) {
		console.log(Course_Id)
		let params = new HttpParams();
		params = params.append('Course_Id', Course_Id);
		return this.httpClient.get(this.globals.apiServerIP + '/api/getTrainierList/',{params:params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}

	updateApprovals(model : any){
		var url = '';
		if(model.Status == 3 && model.Training_Type == 'LMS'){
			url = this.globals.apiServerIP +'/api/assignCourseToEmployee/'
		}
		else{
			url = this.globals.apiServerIP +'/api/updateApproval/';
		}
		return this.httpClient.put(url, model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateApproveDetails(model : {}){
		return this.httpClient.put(this.globals.apiServerIP +'/api/updateApproval/',model)
		.pipe(
		  map((response: any) => {
		  return response;
		  }),
		  catchError(this.handleError)
	  );

	}

	getTrainingReportData(){
		return this.httpClient.get(this.globals.apiServerIP + '/getAssignedTraining')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	deleteCourse(model: any) {
		var url = '';
		if(model.Status == -2 && model.Training_Type == 'LMS'){
			url = this.globals.apiServerIP +'/api/removeUserFromCourse/'
		}
		else{
			url = this.globals.apiServerIP +'/api/updateApproval/';
		}
		return this.httpClient.put(url, model)
			.pipe(
				map((response: any) => {
					return response;3
				}),
				catchError(this.handleError)
			);
	}

	addDailyUtilization(tasklist: any, designationflag: any) {
		var taskdata = { tasklist: tasklist, designationflag: designationflag };
		return this.httpClient.post(this.globals.apiServerIP + '/addDailyUtilization/', taskdata)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}


	getDailyUtilization(queryparams: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/getDailyUtilization/', queryparams)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	getTaggedAccountProject(queryparams: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/getTaggedAccountProject/', queryparams)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	deleteTaskDetails(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/deleteTaskDetails/', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateDailyUtilization(tasklist: any, designationflag: any) {
		var taskdata = { tasklist: tasklist, designationflag: designationflag };
		return this.httpClient.post(this.globals.apiServerIP + '/updateDailyUtilization/', taskdata)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	getReviewPermissions() {
		return this.httpClient.get(this.globals.apiServerIP + '/getReviewPermissions/')
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	getItAssetLovs() {
		return this.httpClient.get(this.globals.apiServerIP + '/getItAssetLovs/')
			.pipe(
				map((response: any) => {
					return response[0].data[0];
				}),
				catchError(this.handleError)
			);
	}

	addItTrackerGeoLocation(queryparams: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/addItTrackerGeoLocation/', queryparams)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	validateAssetTag(queryparams: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/validateAssetTag/', queryparams)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	addAssets(assetList: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/addItAssets/', assetList)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getItAssetList(queryparams: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/getItAssetList/', queryparams)
			.pipe(
				map((response: any) => {
					return response[0];
				}),
				catchError(this.handleError)
			);
	}

	updateAssets(assetList: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/updateItAssets/', assetList)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getAssetHistory(assetTag: any) {
		let params = new HttpParams();
		params = params.append('assetTag', assetTag);

		return this.httpClient.get(this.globals.apiServerIP + '/getAssetHistory/', { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data[0];
				}),
				catchError(this.handleError)
			);

	}

	getItAuditReport(from: any, to: any) {
		let params = new HttpParams();
		params = params.append('from', from);
		params = params.append('to', to);

		return this.httpClient.get(this.globals.apiServerIP + '/getItAuditReport/', { params: params })
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}
	getAssetSummary() {
		return this.httpClient.get(this.globals.apiServerIP + '/assetsSummary/')
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getProjectData(week:string,srFlag:boolean) {
        let params = new HttpParams();
        params = params.append('week', week);
        params = params.append('srFlag', srFlag);

		return this.httpClient.get(this.globals.apiServerIP + '/getProjectHealthData/',{params:params})

			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getActionLogData(sowId:number, week:string) {
        let params = new HttpParams();
        params = params.append('SOW_Id', sowId);
        params = params.append('Week', week);

		return this.httpClient.get(this.globals.apiServerIP + '/getActionLogData/',{params:params})

			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getActionItemData(emp_id:any) {

		let params = new HttpParams();
        params = params.append('emp_id', emp_id);
		return this.httpClient.get(this.globals.apiServerIP + '/getActionItemTab/',{params:params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	CompareData(weekOne:string,weekTwo:string,SOW_Id:any) {
        let params = new HttpParams();
        params = params.append('weekOne', weekOne);
        params = params.append('weekTwo', weekTwo);
        params = params.append('SOW_Id', SOW_Id);
		return this.httpClient.get(this.globals.apiServerIP + '/CompareProjectHealth/',{params:params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}


	showProjectCard(pw:string,cw:string) {
		let params = new HttpParams();
        params = params.append('PreviousWeek', pw);
        params = params.append('CurrentWeek', cw);
		return this.httpClient.get(this.globals.apiServerIP + '/showProjectHealthCard/',{params:params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}


	updateProjectdata(modalData: {}) {
		return this.httpClient.post(this.globals.apiServerIP + '/UpdateProjectHealthData/', modalData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	addProjectdata(modalData: any) {

		return this.httpClient.post(this.globals.apiServerIP + '/AddProjectHealthData/', modalData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateActionLog(modalData: {}) {

		return this.httpClient.post(this.globals.apiServerIP + '/UpdateActionLogStatus/', modalData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	deleteactionLog(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/deleteactionLog', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	addWeekNo(obj :{}){
		return this.httpClient.post(this.globals.apiServerIP + '/AddWeekData/', obj)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	addSow(obj :{}){
		return this.httpClient.post(this.globals.apiServerIP + '/AddSowData/', obj)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getSettingData() {
		return this.httpClient.get(this.globals.apiServerIP + '/getSettingData/')

			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	deleteWeek(id:any){
		return this.httpClient.post(this.globals.apiServerIP + '/deleteweek', id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updatesettingsdata(data : {}){
		return this.httpClient.put(this.globals.apiServerIP + '/UpdateTrainingSettings', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	getTrainingSettingData() {
		return this.httpClient.get(this.globals.apiServerIP + '/getTrainingSettingData')

			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getTrainingJobstatus(){
		return this.httpClient.get(this.globals.apiServerIP + '/getTrainingJobstatus')

		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	getCourseDetails(data:any){
		let params = new HttpParams();
		params = params.append('Start_Date', data.start_date);
		params = params.append('End_Date', data.end_date);
		return this.httpClient.get(this.globals.apiServerIP + '/getCourseDetailsOfEmployee',{params:params})
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);

	}

}
