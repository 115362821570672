import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';

@Injectable()
export class empEduReportsService {

    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
    ){}

    getEmployeeEduReports(data:any){
        var firstUrl = this.globals.apiBaseURL + '/reportsEmpEdu/?status=' +  data.Employee_Status;
        return this.httpClient.get(firstUrl)
            .pipe(
            map((response: any) => {
              return response[0].data;
            }),
            catchError(this.handleError)
            );
  
        
    };

    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
