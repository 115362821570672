import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ServerService {

	constructor(
		private globals: Globals,
		private httpClient: HttpClient,
	) { }
	addEmployee(modelData: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployee';
		return this.httpClient.post(url, modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	addEmployeeBank(model: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployeeBank';
		return this.httpClient.post(url, model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	addEmployeeFamily(model: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployeeFamily';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	addEmployeeMobility(model: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployeeMobility';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	addEmployeeEducation(model: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployeeEducation';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	storeAssetsInfo(model: {}) {
		var url = this.globals.apiBaseURL + '/employeeAssetsInfo';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Add employee experience details.
	addEmployeeExperience(model: {}) {
		let body = model;
		return this.httpClient.post(this.globals.apiBaseURL + '/api/addEmployeeExperience/',body)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);

	}

	// Add employee history details.
	addEmpHistoryDetails(model: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployeeHistory';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Add employee skill details.
	addEmployeeSkills(model: {}) {
		var url = this.globals.apiBaseURL + '/api/addEmployeeSkills';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}


	// Update employee basic details.
	updateEmployeeDetails(modelData: any) {
		var url = this.globals.apiBaseURL + '/api/updateEmployee/';
		url += modelData.Employee_Id !== undefined ? modelData.Employee_Id : "";
		return this.httpClient.put(url,modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Update employee bank details.
	updateEmpBankDetails(modelData: { Employee_Id: undefined; }) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeBank/';
		url += modelData.Employee_Id !== undefined ? modelData.Employee_Id : "";
		return this.httpClient.put(url,modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Update employee family/relation details.
	updateEmpFamilyDetails(modelData: { Employee_Id: undefined; }) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeFamily/';
		url += modelData.Employee_Id !== undefined ? modelData.Employee_Id : "";
		return this.httpClient.put(url,modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Update employee passport details.
	updatePassportDetails(modelData: { Employee_Id: undefined; }) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeMobility/';
		url += modelData.Employee_Id !== undefined ? modelData.Employee_Id : "";
		return this.httpClient.put(url,modelData)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	// Update employee education details.
	updateEducationDetails(modelData: { Employee_Id?: any; }) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeEducation/';
		url += modelData.Employee_Id !== undefined ? modelData.Employee_Id : "";
		return this.httpClient.put(url,modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Update employee experience details.
	updateEmpExperienceDetails(modelData: any) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeExperience';
		return this.httpClient.put(url, modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Delete employee experience details.
	deleteEmpExperienceDetails(expId: string) {
		var url = this.globals.apiBaseURL + '/api/deleteEmployeeExperience/' + expId;
		return this.httpClient.delete(url)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			)
	}

	// Update employee skill details.
	updateEmpSkillsDetails(modelData: any) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeSkills';
		return this.httpClient.put(url,modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	// Update employee history details.
	updateEmpHistoryDetails(modelData: { Company_History_End_date: any; Company_History_Start_date: any; Current_CTC: any; Current_Designation: any; Employee_Id: any; Id: any; Promotion_Recommendation: any; Rating: any; }) {
		var url = this.globals.apiBaseURL + '/api/updateEmployeeHistory';
		return this.httpClient.put(url,modelData
		)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	filterEducation(model: {}, data: string) {
		var url = this.globals.apiBaseURL + '/filterEmpEducation';
		url += "/?status=" + data;
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	filterExperience(model: {}, data: string) {
		var url = this.globals.apiBaseURL + '/filterEmpExperience';
		url += "/?status=" + model;
		return this.httpClient.post(url,data)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	filterContactdetails(model: {}) {
		var url = this.globals.apiBaseURL + '/filterEmpContact';
		return this.httpClient.post(url,model)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	filterPersonalDetails(model:any, data: any) {
		var url = this.globals.apiBaseURL + '/filterPersonalDetails';
		const data1 = encodeURIComponent(JSON.stringify(data));
		url += `?data=${data1}`;
		// url += "/?status=" + data;
		return this.httpClient.post(url,model)
		.pipe(	
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	addTrasferDetails(modelData: { Employee_Id?: any; }) {
		var url = this.globals.apiBaseURL + '/api/transferDetails/';
		url += modelData.Employee_Id !== undefined ? modelData.Employee_Id : "";
		return this.httpClient.put(url,modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	
	DeleteTransfer(Id: any){
		return this.httpClient.put(this.globals.apiServerIP + '/deleteTransfer/', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}


	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}
}
