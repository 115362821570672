import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';

@Injectable()
export class MsaService {

    private accURL= this.globals.apiServerIP +'/MSADetails';
    constructor (
        private httpClient: HttpClient,
        private globals: Globals
    ){}

    getMSAList( accountId : string ) {

        let params = new HttpParams();
        params = params.append('accountId', accountId);

        return this.httpClient.get(
            this.accURL,
            {params: params}
        )
        .pipe(
          map((response:any) => {
             return response[0].data;
          }),
          catchError(this.handleError)
        )
    };

    addMSADetails( model : {} ) {
        
        return this.httpClient.post(
            this.globals.apiServerIP +'/api/addMSA',
            model
        )
        .pipe(
        map((response:any) => {
            return response;
        }),
        catchError(this.handleError)
        )
    }

    updateMSADetails( model : {} ) {
        
        return this.httpClient.put(
            this.globals.apiServerIP +'/api/updateMSA/',
            model
        )
        .pipe(
        map((response:any) => {
            return response;
        }),
        catchError(this.handleError)
        )
    }

    // Delete MSA details.
    deleteMSADetails(MSAId: any) {
        var url = this.globals.apiServerIP + '/api/deleteMSA/' + MSAId ;
        
        return this.httpClient.delete(url)    
        .pipe(
          map((response:any) => {
             return response[0].data;
          }),
          catchError(this.handleError)
       )
    }

    private handleError(error: any): any {
        console.error('An error occurred while fetching the details.', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
