import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';
import { DataCaptureReportComponent } from 'src/app/layout/trackers/components/incentive-tracker/data-capture-report/data-capture-report.component';

@Injectable()
export class EmployeeDetailsService {
  getDesignationData() {
    throw new Error('Method not implemented.');
  }
  getDesignation() {
    throw new Error('Method not implemented.');
  }

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	getBasicDetails(empid: string) {
		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getBasicDetails/', { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getBankDetails(empid: string) {

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getBankDetails/', { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getPayRollDetails(empid: string) {

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getPayRollDetails/', { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}


	getFamilyDetails(empid: string) {

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getFamilyDetails/', { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getPassportDetails(empid: string) {

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getPassportDetails/', { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getEducationDetails(empid: string){

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getEducationDetails/',{ params: params })
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);

	}

	getTransferDetails(empid: string){

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/getTransferDetails/',{ params: params })
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);

	}

	getExperienceDetails(empid: string , type: any){
		let params = new HttpParams();
		params = params.append('employeeid', empid);
		params = params.append('typechanges', type);
		return this.httpClient.get(this.globals.apiBaseURL + '/getExperienceDetails/',{ params: params})
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);

	}

	getSkillsDetails(empid: string){

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getEmpSkills/',{ params: params })
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);

	}


	getHistoryDetails(empid: string){

		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getEmpCompanyHistory/',{ params: params })
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	getEmployeeReportDetails(data: any){
		let params = new HttpParams();
		params = params.append('empId', data.Employee_Id);
		params = params.append('status', data.Employee_Status);
		params = params.append('fromDate', data.fromDate);
		params = params.append('designation', data.Designation);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/employeereportdetails', { params: params })
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	private handleError(error: any) {
		console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	getActiveEmpList(){
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getActiveEmpList')
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	getLoggedEmployeeHierarchy(empid:string){
		let params = new HttpParams();
		params = params.append('employeeid', empid);
		return this.httpClient.get(this.globals.apiBaseURL + '/employeeHierarchy', { params: params })
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

}
