import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SowService {

  private accURL = this.globals.apiServerIP +'/SOWDetails/';
  private sowProjURL = this.globals.apiServerIP +'/ProjectDetails/';
  private sowIncentiveURL = this.globals.apiServerIP +'/incentiveDetails/';
  private AccountSubject = new BehaviorSubject<number>(0);
  accountId = this.AccountSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private globals: Globals
  ){}

  updateSowValue(value: number) {
    this.AccountSubject.next(value);
  }
    
  getSOWList( reqObj : any ) {
    let params = new HttpParams();
    reqObj.MSAId ? params = params.append('MSAId', reqObj.MSAId) : "";
    reqObj.account_Id ? params = params.append('account', reqObj.account_Id) : "";
    reqObj.consideration ? params = params.append('consideration', reqObj.consideration) : "";
    reqObj.getAllRevenueSOWOfThread ? params = params.append('getAllRevenueSOWOfThread', reqObj.getAllRevenueSOWOfThread) : "";

    return this.httpClient.get(this.accURL,{params: params})
    .pipe(
      map((response:any) => {
          return response[0].data;
      }),
      catchError( this.handleError )
      )
  };

  getSOWProject( reqObj : string ) : any {
    let params = new HttpParams();
    params = params.append('id', reqObj);

    return this.httpClient.get(this.sowProjURL, { params: params })
    .pipe(
      map((response: any) => {
        return response[0].data;
      }),
      catchError(this.handleError)
      )
  };

  getSOWIncentive( reqObj : string ): any {
    let params = new HttpParams();
    params = params.append('id', reqObj);
    params = params.append('token',  this.globals.getLoginUserToken());

    return this.httpClient.get(
      this.sowIncentiveURL,
      {params: params}
    )
    .pipe(
      map((response:any) => {
          return response[0].data;
      }),
      catchError(this.handleError)
      )
  };

  addSOWDetails( model: {} ) {

    return this.httpClient.post(
      this.globals.apiServerIP +'/api/addSOW/',
      model
      )
      .pipe(
        map((response:any) => {
            return response;
        }),
        catchError(this.handleError)
      )
  };

  updateSOWDetails( model : {} ) {
    return this.httpClient.post(
      this.globals.apiServerIP +'/api/updateSOW/',
      model
      )
      .pipe(
        map((response:any) => {
            return response;
        }),
        catchError(this.handleError)
      )
  };

  approveSOWDetails( model : {} ) {

    return this.httpClient.put(
      this.globals.apiServerIP +'/api/approveSOW/',
      model
        )
        .pipe(
          map((response:any) => {
              return response;
          }),
          catchError(this.handleError)
        )
  };

  // Delete SOW details.
  deleteSOWDetails( SOWId : string ) {
    var url = this.globals.apiServerIP + '/api/deleteSOW/' + SOWId ; 

    return this.httpClient.delete(url)
    .pipe(
      map((response:any) => {
          return response;
      }),
      catchError(this.handleError)
    )
  };

  // incentive fields datalist data

  getIncentiveDatalistData( account_id: string | number | boolean ): any {

    let params = new HttpParams();
    params = params.append('accountId', account_id);

    return this.httpClient.get(
      this.globals.apiBaseURL + '/getIncentiveLovs/',
      {params: params}
    )
    .pipe(
      map((response:any) => {
         return response[0].data;
      }),
      catchError(this.handleError)
   )

  };

  // incentive fields datalist data

  getSOWNumber( sow_id: string | number | boolean, account_id: string | number | boolean ): any {

    let params = new HttpParams();
    params = params.append('SOW_Id', sow_id);
    params = params.append('accountId', account_id);

    return this.httpClient.get(this.globals.apiBaseURL + '/generateSOWNumber/',{params: params})
    .pipe(
      map((response:any) => {
         return response[0].data;
      }),
      catchError(this.handleError)
   )
  };


  private handleError(error: any): Promise<any> {
      //this.spinner.hide();
      console.error('An error occurred while fetching the details.', error); // for demo purposes only
      return Promise.reject(error.message || error);
  }

}
