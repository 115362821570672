<nav class="sidebar" [ngClass]="{sidebarPushRight: isActive}">
    <ul class="list-group">
        <li *ngIf="!hideProfile">
            <a [routerLink]="['/dashboard']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Dashboard">
                <i class="bi bi-grid"></i>
                <h6>Dashboard</h6>
                <i class="bi bi-grid shortmenu"></i>
            </a>
        </li>
        <li *ngIf="!hideProfile">
            <a [routerLink]="'/profile/' + username" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Employee Profile">
                <i class="bi bi-person-fill"></i>
                <h6>Profile</h6>
                <i class="bi bi-person-fill shortmenu"></i>

            </a>
        </li>
        <!-- <a *ngIf="!hideProfile" [routerLink]="'/basic/' + username" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Employee Profile">
            <i class="fa fa-fw fa-user"></i>
            <h6 class="text-profile">{{ 'myprofile' | translate }}</h6>
        </a> -->
        <li *ngIf="!hideReport">
            <a [routerLink]="['/reports']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Reports">
                <i class="bi bi-file-earmark-spreadsheet"></i>
                <h6>Reports</h6>
                <i class="bi bi-file-earmark-spreadsheet shortmenu"></i>
            </a>
        </li>
        <li *ngIf="!hideAdmin">
            <a [routerLink]="['/admin']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Administrator">
                <i class="fa fa-cogs"></i>
                <h6>Admin</h6>
                <i class="fa fa-cogs shortmenu"></i>

            </a>
        </li>
        <li *ngIf="!hideResource">
            <a [routerLink]="['/resources']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Accounts & Resources">
                <i class="bi bi-box-seam"></i>
                <h6>Accounts</h6>
                <i class="bi bi-box-seam shortmenu"></i>
            </a>
        </li>
        <li *ngIf="!hideRevenue">
            <a [routerLink]="['/revenue']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Revenue">
                <i class="bi bi-coin"></i>
                <h6>Revenue</h6>
                <i class="bi bi-coin shortmenu"></i>
            </a>
        </li>

        <li *ngIf="!hideHiring">
            <a [routerLink]="['/hiring']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Hiring Requests">
                <i class="bi bi-briefcase"></i>
                <h6>Hiring</h6>
                <i class="bi bi-briefcase shortmenu"></i>
            </a>
        </li>
        <li *ngIf="!hideProfile">
            <a [routerLink]="['/trackers']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Quality Analysis">
                <i class="bi bi-bar-chart-line"></i>
                <h6>Trackers</h6>
                <i class="bi bi-bar-chart-line shortmenu"></i>
            </a>
        </li>
        <li *ngIf="!hideProfile">
            <a [routerLink]="['/tickets']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Tickets">
                <i class="fa fa-ticket"></i>
                <h6>Tickets</h6>
                <i class="fa fa-ticket shortmenu"></i>
            </a>
        </li>
        <li *ngIf="showAwards == true">
            <a [routerLink]="['/skilltracker']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Skills">
                <i class="bi bi-vector-pen"></i>
                <h6>Skills</h6>
                <i class="bi bi-vector-pen shortmenu"></i>
            </a>
        </li>
        <li *ngIf="!hideTravel">
            <a [routerLink]="['/travel']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Travel Requests">
                <i class="fa fa-fw fa-plane"></i>
                <h6>Travel</h6>
                <i class="fa fa-fw fa-plane shortmenu"></i>
            </a>
        </li>
        <li *ngIf="showAwards == true">
            <a [routerLink]="['/transformeraward']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Transformer Awards">
                <i class="bi bi-award"></i>
                <h6>Awards</h6>
                <i class="bi bi-award shortmenu"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/appraisal']" [routerLinkActive]="['router-link-active']" class="list-group-item" title="Appraisal Process">
                <i class="bi bi-graph-up-arrow"></i>
                <h6>Appraisal</h6>
                <i class="bi bi-graph-up-arrow shortmenu"></i>
            </a>
        </li>
        <!-- <a  [routerLink]="['/cron']" [routerLinkActive]="['router-link-active']"
        class="list-group-item"  title="Cron Jobs">
            <i class="fa fa-fw fa-clock-o"></i>
            <span class="text-profile">{{ 'Cron' | translate }}</span>
        </a> -->
    </ul>
</nav>