import { Pipe, PipeTransform } from '@angular/core';
import { CommonDataStreamService } from '../services';

@Pipe({
  name: 'designation'
})
export class DesignationPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(designationId: string): string {

    let items: any = this.cds.dataStream.designations;

    let resList: any;
    let designation: string = '';

    if(!items) return designationId;

    if(!designationId) return '';

    resList = items.find( (it: { Designation_Id: string; }) => {
      return it.Designation_Id == designationId;
    });

    if ( resList ) {
      designation = resList.Designation_Name;
      let subGrp = resList.Group_Name;
      if ( subGrp ) {
        designation += ` (${subGrp})`;
      }
    } else {
      designation = designationId;
    }

    return designation;
  }
}


@Pipe({
  name: 'newdesignation'
})
export class NewDesignationPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(designationId: string): string {

    let items: any = this.cds.dataStream.designations;

    let resList: any;
    let designation: string = '';
    let department: string = '';

    if(!items) return designationId;

    if(!designationId) return '';

    resList = items.find( (it: { Designation_Id: string; }) => {
      return it.Designation_Id == designationId;
    });

    if ( resList ) {

      let subGrp = resList.Group_Name;
      let grp = resList.Sub_Group_Name;

      designation = resList.Designation_Name;
      if ( subGrp ) {
        designation += ` (${subGrp})`;
      }

      department = resList.Department_Name;
      if ( grp ) {
          department += ` > ${grp}`;
      }

    } else {
      designation = designationId;
    }

    return designation + "\n" + department;
  }
}

@Pipe({
  name: 'aadesignation'
})
export class AADesignationPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(designationId: string, format?:string): string {

    let items: any = this.cds.dataStream.designations;

    let resList: any;
    let designation: string = '';
    let department: string = '';

    if(!items) return designationId;

    if(!designationId) return '';

    resList = items.find( (it: { Designation_Id: string; }) => {
      return it.Designation_Id == designationId;
    });

    if ( format === 'd-s' ) {
      return resList.Designation_Name + "-" + resList.Group_Name;
    } else {
      return resList.Designation_Name;
    }
  }
}

@Pipe({
  name: 'department'
})
export class DepartmentPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(designationId: string): string | any {

    let items: any = this.cds.dataStream.designations;

    let resList: any;
    let designation: string = '';
    let department: string = '';

    if(!items) return designationId;

    if(!designationId) return '';

    resList = items.find( (it: { Designation_Id: string; }) => {
      return it.Designation_Id == designationId;
    });

    if ( resList ) {

      let subGrp = resList.Group_Name;
      let grp = resList.Sub_Group_Name;
      designation = resList.Designation_Name;
      department = resList.Department_Name;

    return department;
  }
}
}

@Pipe({
  name: 'group'
})
export class GroupPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(designationId: string): string | any {

    let items: any = this.cds.dataStream.designations;

    let resList: any;
    let designation: string = '';
    let department: string = '';

    if(!items) return designationId;

    if(!designationId) return '';

    resList = items.find( (it: { Designation_Id: string; }) => {
      return it.Designation_Id == designationId;
    });

    if ( resList ) {

      let subGrp = resList.Group_Name;
      let grp = resList.Sub_Group_Name;
      designation = resList.Designation_Name;
      department = resList.Department_Name;

    return grp;
  }
}
}

@Pipe({
  name: 'subgroup'
})
export class SubGroupPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(designationId: string): string | any {

    let items: any = this.cds.dataStream.designations;

    let resList: any;
    let designation: string = '';
    let department: string = '';

    if(!items) return designationId;

    if(!designationId) return '';

    resList = items.find( (it: { Designation_Id: string; }) => {
      return it.Designation_Id == designationId;
    });

    if ( resList ) {

      let subGrp = resList.Group_Name;
      let grp = resList.Sub_Group_Name;
      designation = resList.Designation_Name;
      department = resList.Department_Name;

    return subGrp;
  }
}
}
