import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';
@Injectable()
export class PasswordService {

  private httpOptions: any = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
    ) {}

    changePassword(modelData: {}) {
        return this.httpClient.post(
            this.globals.apiBaseURL + '/api/changePassword/',
            modelData,
            this.httpOptions
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
    }

    // On error.
    private handleError(error: any): Promise<any> {
      console.error('An error occurred while fetching the employee details.', error);
      return Promise.reject(error.message || error);
    }

}
