import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AppraisalService {

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	/*getManagerList() {
		let params = new HttpParams();
		params = params.append('accountId', '1');
		return this.httpClient.get(this.globals.apiBaseURL + '/getIncentiveLovs/', { params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
	}*/

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the details', error);
		return Promise.reject(error.message || error);
	}

	getKraMasterData(modelData: { KRA_Type: any; }) {
		var urlKra = this.globals.apiBaseURL + '/api/getKraMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	addKraMaster(modelData: {}) {
		var urlKra = this.globals.apiBaseURL + '/api/addKraMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	updateKraMaster(modelData: {}, Id: any) {
		var urlKra = this.globals.apiBaseURL + '/api/updateKraMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	deleteKRAMaster(modelData: {}) {
		var urlKra = this.globals.apiBaseURL + '/api/deletekraMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	getKpiMasterData(modelData: { KRA?: any; }) {
		var urlKra = this.globals.apiBaseURL + '/api/getKpiMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	addKpiMaster(modelData: {}) {
		var urlKra = this.globals.apiBaseURL + '/api/addKpiMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	updateKpiMaster(modelData: {}) {
		var urlKra = this.globals.apiBaseURL + '/api/updateKpiMasterData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	deleteKPIMaster(Id: string) {
		var url = this.globals.apiBaseURL + '/api/deletekpiMasterData/' + Id;
		return this.httpClient.delete(url)
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
	}

	getKpiValueMasterData(Id: string | number | boolean) {
		let params = new HttpParams();
		params = params.append('KPI_Id', Id);
		return this.httpClient.get(
			this.globals.apiBaseURL + '/getKpiValueMasterData',
			{ params: params }
		)
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
	}

	getAppraisalLovs(period :  any) {
		let params = new HttpParams();
		params = params.append('period', period);
		return this.httpClient.get(
			this.globals.apiBaseURL + '/api/getAppraisalLovs', { params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
	}

	getTemplateMaster(Period: string | number | boolean) {
		let params = new HttpParams();
		params = params.append('Period', Period)
		return this.httpClient.get( this.globals.apiBaseURL + '/api/getTemplateMasterData',{ params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
	}

	copyTemplates(period={}) {
		return this.httpClient.post( this.globals.apiBaseURL + '/api/copyTemplateData',period)
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
	}

	getEligibilityData(Period: number){
		let params = new HttpParams();
		params = params.append('Period',Period)
		return this.httpClient.get( this.globals.apiBaseURL + '/api/getEligibilityData',{ params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
		
		
	}

    getTransferEmployeeData(cutOffDate: string){
		let params = new HttpParams();
		params = params.append('cutOffDate',cutOffDate)
		return this.httpClient.get( this.globals.apiBaseURL + '/api/getTransferEmployeeData',{ params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;
			}),
			catchError(this.handleError)
		);
		
		
	}

    addEligibleEmp(empList:any,period:number){
        return this.httpClient.post( this.globals.apiBaseURL + '/api/addEligibleEmp',{empList:empList,period:period})
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
    }

    deleteEligibleEmp(empList:any,period:number){
        return this.httpClient.post( this.globals.apiBaseURL + '/api/deleteEligibleEmp',{empList:empList,period:period})
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
    }

	updateEmployeesIntendedRating(ratingData : any = []){
		return this.httpClient.post(this.globals.apiBaseURL + '/api/updateIntendedRating',ratingData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);

	}
	getSelectedTemplateData(period: string | number | boolean,designation: string | number | boolean,location: string | number | boolean,workPlace: string | number | boolean, empId : string) {
		let params = new HttpParams();
		params = params.append('designation', designation)
		params = params.append('period', period)
		params = params.append('location', location)
		params = params.append('workPlace', workPlace)
		params = params.append('empId', empId)
		return this.httpClient.get( this.globals.apiBaseURL + '/api/getSelectedTemplateData',{ params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;   			
			}),
			catchError(this.handleError)
		);
	}

	createTemplate(modelData: {}) {
		var url = this.globals.apiBaseURL + '/api/createTemplate/';
		return this.httpClient.post(url,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	updateTemplate(modelData: {}) {
		var url = this.globals.apiBaseURL + '/api/updateTemplate/';
		return this.httpClient.post(url,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	approveTemplate(modelData: {}) {
		var url = this.globals.apiBaseURL + '/api/approveTemplate/';
		return this.httpClient.post(url,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	deleteTemplate(modelData: {}) {
		var url = this.globals.apiBaseURL + '/api/deleteTemplate/';
		return this.httpClient.post(url,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	getSelfAssessmentData(modelData: {}) {
		var urlTemplate = this.globals.apiBaseURL + '/api/getSelfAssessmentData/';
		return this.httpClient.post(urlTemplate,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	addSelfAssessmentData(modelData: {}) {
		var urlKra = this.globals.apiBaseURL + '/api/addSelfAssessmentData/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	//GET Apprasial Summary
	getAppraisalSummary(period: string | number | boolean, userLevel: string | number | boolean) {
		let params = new HttpParams();
		params = params.append('period', period);
		params = params.append('userLevel', userLevel);
		return this.httpClient.get( this.globals.apiBaseURL + '/api/getAppraisalSummary',{ params: params })
		.pipe(
			map((response: any) => {
			return response[0].data;   
			}),
			catchError(this.handleError)
		);
	}

	//UPDATE Appraisal Summary
	updateAppraisalSummary(modelData: any) {
		var url = this.globals.apiBaseURL + '/api/updateAppraisalSummary/';
		return this.httpClient.post(url,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			
			}),
			catchError(this.handleError)
		);
		
		
	}

	/* addUpcomingGoals */
	addGoalsData(upComingGoalsData: {}) {
		var url = this.globals.apiBaseURL + '/api/addGoals/';
		return this.httpClient.post(url,upComingGoalsData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	getGoalsData(goalsGetPara: any) {
		let params = new HttpParams();
		params = params.append('employeeId', goalsGetPara.Employee_Id);
		params = params.append('period', goalsGetPara.Period);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getGoals/',{ params: params })
			.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	updateAppraisalStatus(modelData: any){
		var urlKra = this.globals.apiBaseURL + '/api/updateAppraisalStatus/';
		return this.httpClient.post(urlKra,modelData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}


	openQuarterlyDiscussion(modelData: {}) {
		return this.httpClient.post(this.globals.apiBaseURL + '/api/openQuarterlyDiscussion/',modelData)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		);
	}

	getQuarterlyDiscussionList(period:string|number){
		let params = new HttpParams();
		params = params.append('period', period);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getQuarterlyDiscussionList/',{ params: params })
			.pipe(
			map((response: any) => {
			return response[0].data;
			}),
			catchError(this.handleError)
		);
	}


	/* addUpcomingGoals */
	UpdateGoalsData(GoalsData: {}) {
		var url = this.globals.apiBaseURL + '/api/updateGoals/';
		return this.httpClient.post(url,GoalsData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}
	
	getQuarterlyDiscussionStatus(period:string,empId:string){
		let params = new HttpParams();
		params = params.append('period', period);
		params = params.append('Employee_Id', empId);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getQuarterlyDiscussionStatus/',{ params: params })
			.pipe(
			map((response: any) => {
			return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	/* addUpcomingGoals */
	UpdateaprSummaryStatus(GoalsData: {}) {
		var url = this.globals.apiBaseURL + '/api/updateaprQuarterlySummary/';
		return this.httpClient.post(url,GoalsData)
		.pipe(
			map((response: any) => {
			return response;   
			}),
			catchError(this.handleError)
		);
	}

	updateApprisalPeriod(modelData={}) {
		var urlApr = this.globals.apiBaseURL + '/api/updateAppraisalPeriod/';
		return this.httpClient.post(urlApr,modelData)
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
	}

    getActivePeriodStages(period:number){
		let params = new HttpParams();
		params = params.append('period_id', period);
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getActivePeriodStages/',{ params: params })
			.pipe(
			map((response: any) => {
			return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	getPeriodList(){
		let params = new HttpParams();
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getPeriodList/')
			.pipe(
			map((response: any) => {
			return response[0].data;
			}),
			catchError(this.handleError)
		);
	}


    updateApprisalStage(modelData={}) {
		var urlApr = this.globals.apiBaseURL + '/api/updateAppraisalStage/';
		return this.httpClient.post(urlApr,modelData)
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
	}

	updateManagerRemarks(modelData={}) {
		var urlApr = this.globals.apiBaseURL + '/api/updateManagerRatings';
		return this.httpClient.post(urlApr,modelData)
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
	}

	getFasttrackData(){
		let params = new HttpParams();
		return this.httpClient.get(this.globals.apiBaseURL + '/api/getFastTrackPromotion/')
			.pipe(
			map((response: any) => {
			return response[0].data;
			}),
			catchError(this.handleError)
		);
	}

	calucaluteSystemRating(modelData = {}){
		var urlApr = this.globals.apiBaseURL + '/api/calculateRating/';
		return this.httpClient.post(urlApr,modelData)
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
	}

	saveFinalRating(modelData = {}){
		var urlApr = this.globals.apiBaseURL + '/api/saveFinalRating/';
		return this.httpClient.post(urlApr,modelData)
		.pipe(
			map((response: any) => {
			return response;
			}),
			catchError(this.handleError)
		);
	}

}


