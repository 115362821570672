import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../global';
import {throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthenticateService {

  private url = this.globals.apiBaseURL + '/authenticate';

  constructor(
	private globals: Globals,
	private httpClient: HttpClient,
  ){}

  authenticateUser(model: { username: any; password: any; }) {
	var secureUsercreds = { username: model.username, password: model.password };

	return this.httpClient.post( this.url,  secureUsercreds)
	.pipe(
		map((response: any) => {
			return response;
		}),
		catchError(this.handleError)
	);
  }

  resetUserPassword(model: {}) {
	let body = model;

	return this.httpClient.post( this.globals.apiBaseURL + '/api/forgotPassword', body)
	.pipe(
		map((response : any) => {
			return response;
		}),
		catchError(this.handleError)
	);
  }

  // On error.
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
	let errorMessage = '';
	if(error.error instanceof ErrorEvent) {
	  // Get client-side error
	  errorMessage = error.error.message;
	} else {
	  // Get server-side error
	  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
	}
	window.alert(errorMessage);
	return throwError(() => errorMessage);
 }

  
  

}
