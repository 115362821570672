import { Pipe, PipeTransform } from '@angular/core';
import { CommonDataStreamService } from '../services';

@Pipe({
  name: 'ratingDescription'
})
export class RatingDescriptionPipe implements PipeTransform {

  items: any;

  constructor(
    private cds: CommonDataStreamService
  ) {}

  transform(ratingId: string): string {

    let items: any = this.cds.dataStream.ratings;

    let resList: any[];
    let rating: string = '';

    if(!items) return ratingId;

    if(!ratingId) return '';

    resList = items.filter( (it: { Id: string; }) => {
      return it.Id == ratingId;
    });

    if ( resList && resList.length ) {
      rating = resList[0].Rating_Desc;
    } else {
      rating = ratingId;
    }

    return rating;
  }
}
