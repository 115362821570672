export class EmpRepDetails {
    'Employee_Id': string;
    'Employee_Name': string;
    'HSC_Passing': string;
    'HSC_Marks': string;
    'HSC_Institute': string;
    'HSC_Board': string;
    'SSC_Passing': string;
    'SSC_Marks': string;
    'SSC_Institute': string;
    'SSC_Board': string;
    'GRAD_Passing': string;
    'GRAD_Marks': string;
    'GRAD_Institute': string;
    'GRAD_Board': string;
    'PG_Passing': string;
    'PG_Marks': string;
    'PG_Institute': string;
    'PG_Board': string;
    'Certification_Title': string;
    'Certification_Provider': string;
    'Certification_Details': string;
    'Training_Title': string;
    'Training_Provider': string;
    'Training_Duration': string;
    'Course_Details': string;
}
