import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CommonDataStreamService }  from './commondatastream.service';
import { EmployeeIdNameService } from '../employeeidname/employeeidname.service';

@Injectable()
export class CDSDataResolver implements Resolve<any> {
  constructor( 
    private cds: CommonDataStreamService,
    private router: Router,
    public employeeIdNameService: EmployeeIdNameService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.cds.fetchPrerequistes()
  }
}
