import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../global';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map } from 'rxjs';

@Injectable()
export class ProfitabilityService {

    private accURL= this.globals.apiServerIP +'/ProfitabilityDetails';
    private proURL= this.globals.apiServerIP +'/getActualProfitability';
    constructor(
      private httpClient: HttpClient,
        private globals: Globals,
        private spinner: NgxSpinnerService

    ){}

    private httpOptions: any = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    // getProfitabilityList(accountId) {
    //   const headers = new Headers({'Content-Type': 'application/json'});
    //   return this.httpClient.get(this.accURL);
    // };
    getProfitabilityList(SOWId: string) {

        let params = new HttpParams();
        params = params.append('SOWId', SOWId);//We fetch profitability based on account

        return this.httpClient.get(
            this.accURL,
            {params: params}
        )
        .pipe(
            map((response:any)=> {
              return response[0].data;
            }),
            catchError(this.handleError)
            );

    };

    getActualProfitability(SOWId: string) {

        let params = new HttpParams();
        params = params.append('SOWId', SOWId);//We fetch profitability based on account

        return this.httpClient.get(
            this.proURL,
            {params: params}
        )
        .pipe(
            map((response:any)=> {
              return response[0].data;
            }),
            catchError(this.handleError)
            );

    };



    addProfitabilityDetails(model: {}) {
        const headers = new Headers({'Content-Type': 'application/json'});
        return this.httpClient.post(
            this.globals.apiServerIP +'/api/addProfitability?token='+ this.globals.getLoginUserToken(),
            model,
            this.httpOptions
            )
            .pipe(
                map((response:any) => {
                   this.spinner.hide();//hide wait indicator
                   return response;
                }),
                catchError(this.handleError)
             )
    }

    updateProfitabilityDetails(model: {}) {
        const headers = new Headers({'Content-Type': 'application/json'});
        return this.httpClient.put(
            this.globals.apiServerIP +'/api/updateProfitability?token='+ this.globals.getLoginUserToken(),
            model,
            this.httpOptions
            )
            .pipe(
                map((response:any) => {
                   this.spinner.hide();//hide wait indicator
                   return response;
                }),
                catchError(this.handleError)
             )
    }
    // Delete Profitability details.
    deleteProfitabilityDetails(P_Id:any) {
        var url = this.globals.apiServerIP + '/api/deleteProfitability/' + P_Id + '?token='+ this.globals.getLoginUserToken();
        const headers = new Headers({'Content-Type': 'application/json'});
        return this.httpClient.delete(url)
        .pipe(
            map((response:any) => {
               return response;
            }),
            catchError(this.handleError)
         )
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the details.', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
