import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../../global';
import { map, catchError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SolutionsService {

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}


	createSolution(params:any) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/createSolutionRequest/', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
	
	updateSolution(model: {}) {
		return this.httpClient.put(this.globals.apiServerIP +'/api/updateSolutionRequest/',model)
		.pipe(
		  map((response: any) => {
		  return response;
		  }),
		  catchError(this.handleError)
	  );
	}

	getSolution(emp_id: string) {
		return this.httpClient.get(this.globals.apiServerIP + '/api/getMySolutionRequests/' + '?employeeid=' + emp_id)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getIdealVisualList(emp_id: string) {
		return this.httpClient.get(this.globals.apiServerIP + '/api/getIdealVisualList/')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}


	getSolutionRequestsForApproval(filterData: any) {
		let params = new HttpParams();
		params = params.append('Status', filterData.Status_Code);
		params = params.append('Priority', filterData.Priority);
		params = params.append('Assigned_To', filterData.Assigned_To);
		params = params.append('Region', filterData.Region);
		params = params.append('Access_Type', filterData.Access_Type);
		params = params.append('role', filterData.role);
		params = params.append('empId', filterData.empId);
		params = params.append('type', filterData.type);
		return this.httpClient.get(this.globals.apiServerIP + '/api/getSolutionRequestsForApproval/',{params:params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}

	getAssignedToList() {

		return this.httpClient.get(this.globals.apiServerIP + '/api/getSolutionRequestsAssignees/')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getSolutionDownloadData(){
		return this.httpClient.get(this.globals.apiServerIP +'/api/getSolutionDownloadData/')
		.pipe(
			map((res: any) =>{
				return res[0].data;
			}),
			catchError(this.handleError)
		)
	}

	getSolSettingData(){
		return this.httpClient.get(this.globals.apiServerIP +'/api/getSolSettingData/')
		.pipe(
			map((res: any) =>{
				return res[0].data;
			}),
			catchError(this.handleError)
		)
	}

	addSolSettingData(model: {}) {
		return this.httpClient.post(this.globals.apiServerIP +'/api/addSolSettingData/',model)
		.pipe(
		  map((response: any) => {
		  return response;
		  }),
		  catchError(this.handleError)
	  );
	}

	deleteSolApprover(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/deleteSolApprover', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
}