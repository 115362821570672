import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Compiler } from '@angular/core';

import { Router } from '@angular/router';
import { Globals } from './shared';

import { UserIdleService } from 'angular-user-idle';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private translate: TranslateService,
        private _compiler: Compiler,
        private userIdle: UserIdleService,
        private router: Router,
        private global: Globals

    ) {
        translate.addLangs(['en', 'fr', 'ur', 'es']);
        translate.setDefaultLang('en');
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang?.match(/en|fr|ur|es/) ? browserLang : 'en');
        this._compiler.clearCache();
    }

    ngOnInit() {
        this.userIdle.startWatching();
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(count => {
            this.handleUserIdle();
        });
    
        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => console.log('Time is up!'));
    }
    
    handleUserIdle() {
        this.userIdle.stopWatching();  // Stop watching to prevent repeated actions.
        this.global.logout();  // Handle user logout logic.
    
        // Navigate to login and refresh necessary components/services.
        this.router.navigate(['/login']).then(() => {
            this.reinitializeComponents();
        });
    }
    
    reinitializeComponents() {
        this.global.logout();

        // Place logic here to reinitialize necessary components or services.
        // For example, refresh data, reset forms, etc.
    }
}
