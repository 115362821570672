import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeIdNameService } from '../services';

@Pipe({
  name: 'empFullnameId'
})
export class EmpFullnameIdPipe implements PipeTransform {

  items: any = [];

  constructor(
    private employeeIdNameService: EmployeeIdNameService
  ){
    this.items = this.employeeIdNameService.empIdNames;
  }

  transform(empId: string): string {

    let empIdName : string = '';
    let resList: any[];

    if(!this.items) return empId;
    if(!empId) return '';
    empId = empId.toLowerCase();

    resList = this.items.filter( (it: { Employee_Id: string; }) => {
      return it.Employee_Id.toLowerCase() == empId.toLowerCase();
    });

    if ( resList && resList.length ) {
      empIdName = resList[0].Employee_Name_Id;
    } else {
      empIdName = empId;
    }

    return empIdName;
  }
}
