import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class EmployeeLeaveService {

	private url1 = this.globals.apiBaseURL + '/api/getEmployeeLeaves';
	private url2 = this.globals.apiBaseURL + '/api/getEmployeeLeaveTypes';
	private url3 = this.globals.apiBaseURL + '/api/getLeavesForApprove';
	private url4 = this.globals.apiBaseURL + '/api/getOtherEmployeeList';

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the list of employees', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	getEmployeeLeaveUpdates(empid: string) {
		return this.httpClient.get(this.url1 + '?employeeid=' + empid)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	addLeaveDetails(model: {}) {
		return this.httpClient.post(
			this.globals.apiServerIP + '/api/applyLeave',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeLeaveTypes(empid: string) {
		return this.httpClient.get(this.url2 + '?employeeid=' + empid)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeLeaveApprover(empid: string) {
		return this.httpClient.get(	this.url3 + '?employeeid=' + empid)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeList(Leaveid: string) {
		return this.httpClient.get(this.url4 + '?Leaveid=' + Leaveid)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	UpdateEmployeeLeave(model: {}) {
		return this.httpClient.put(this.globals.apiServerIP + '/api/updateLeaveData',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	UpdateEmployeeAproveLeave(model: {}) {
		return this.httpClient.put(this.globals.apiServerIP + '/api/UpdateLeaveApproverData',model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	ApproveRejectLeave(model: {}) {
		return this.httpClient.put(this.globals.apiServerIP + '/api/approveAppliedLeave', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	/* formats the date in yy-mm-dd */
	DateFormat() {
		var d = new Date(),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}

	/* calculates the number of days between 2 dates */
	DaysCalculation(from_date: string | number | Date, to_date: string | number | Date) {
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		const firstDate: any = new Date(from_date);
		const secondDate: any = new Date(to_date);

		// var time_difference = secondDate.getTime() - firstDate.getTime();  
		// var diffDays = time_difference / (1000 * 60 * 60 * 24);  

		return Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
	}
}
