import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from '@ngx-translate/core';
import { AaDesignationComponent } from './aa-designation.component';
import { AaDesignationRoutingModule } from './aa-designation-routing.module';

import { SharedPipesModule } from '../../pipes';

@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      SharedPipesModule,
      AaDesignationRoutingModule
  ],
  exports: [
    AaDesignationComponent
  ],
  declarations: [AaDesignationComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AaDesignationModule { }