import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../global';

import { catchError, map } from 'rxjs';

@Injectable()
export class QAService {

	private getWeeklyQA = this.globals.apiServerIP + '/api/getQAWeeklyData/';

	constructor(
		private httpClient: HttpClient,
		private globals: Globals
	) { }

	getWeeklyData(reqObj: string | number | boolean) {
		let params = new HttpParams();
		reqObj ? params = params.append('week', reqObj) : "";

		return this.httpClient.get(this.getWeeklyQA, { params: params })
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	};

	addQAAnalysis(model: {}) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/addWeeklyQAAnalysis/', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	// Delete SOW details.
	deleteQADetails(QA_Id: string) {
		var url = this.globals.apiServerIP + '/api/deleteQA/' + QA_Id;
		return this.httpClient.delete(url)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

}
