<textarea name="name" rows="2" cols="80" class="form-control" name="Desg-Dept" [name]="name" [ngModel]="value | newdesignation" (ngModelChange)="value=$event" [attr.disabled]="editable ? null : true" title="Designation (Sub-Group) Department > Group"
    readonly (click)="toggleDesgEdit()" required>
</textarea>

<div class="row aa-pop-out" *ngIf="showPopup" (click)="handleClick($event.target)">

    <form class="col-12 aa-desg-form" novalidate #aaDesgForm="ngForm" (ngSubmit)="emitNewDesignationDetails(aaDesgForm)">

        <div class="col-4 aa-po-container">

            <div class="row department">
                <label class="col-3" for="">Department <span class="pull-right"> : </span> </label>
                <select class="col-9 test" [attr.disabled]="null" name="Department" [(ngModel)]="defDpt" (change)="loadChildren([['Department_Id', $any($event.target).value]], 'Group')" required title="Department">
          <option value="" disabled selected hidden>Select Department</option>
          <option *ngFor="let desg of Departments" value="{{ desg.Department_Id }}">
            {{ desg.Department_Name }}
          </option>
        </select>
            </div>
            <div class="row Group">
                <label class="col-3" for="">Group <span class="pull-right"> : </span> </label>
                <select class="col-9 test" [attr.disabled]="null" name="Group" [(ngModel)]="defGrp" (change)="loadChildren([['Group_Id', $any($event.target).value],['Department_Id', defDpt]], 'Sub_Group')" required title="Group">
        <option value="" disabled selected hidden>Select Group</option>
          <option *ngFor="let grp of Groups" value="{{ grp.Group_Id }}">
            {{ grp.Group_Name }}
          </option>
        </select>
            </div>
            <div class="row Sub_Group">
                <label class="col-3" for="">Sub Group <span class="pull-right"> : </span> </label>
                <select class="col-9 test" [attr.disabled]="null" name="Sub_Group" [(ngModel)]="defSubGrp" (change)="loadChildren([['Sub_Group_Id', $any($event.target).value],['Department_Id', defDpt],['Group_Id', defGrp]], 'Level')" required title="Sub_Group">
        <option value="" disabled selected hidden>Select Sub-Group</option>
          <option *ngFor="let subgrp of Sub_Groups" value="{{ subgrp.Sub_Group_Id }}">
            {{ subgrp.Sub_Group_Name }}
          </option>
        </select>
            </div>
            <div class="row level">
                <label class="col-3" for="">Level <span class="pull-right"> : </span> </label>
                <select class="col-9 test" [attr.disabled]="null" name="Level" [(ngModel)]="defLvl" (change)="loadChildren([['Level_Id', $any($event.target).value],['Department_Id', defDpt],['Group_Id', defGrp],['Sub_Group_Id', defSubGrp]], 'Designation')" required title="Level">
        <option value="" disabled selected hidden>Select Level</option>
          <option *ngFor="let lvl of Levels" value="{{ lvl.Level_Id }}">
            {{ lvl.Level_Name }}
          </option>
        </select>
            </div>
            <div class="row Designation">
                <label class="col-3" for="">Designation <span class="pull-right"> : </span> </label>
                <select class="col-9 test" [attr.disabled]="null" name="Designation" [(ngModel)]="defDsg" required title="Designation">
          <option value="" disabled selected hidden>Select Designation</option>
          <option *ngFor="let dsg of Designations" value="{{ dsg.Designation_Id }}">
            {{ dsg.Designation_Name }}
          </option>
        </select>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <button type="submit" name="button" class="btn btn-sm btn-purple pull-right ms-1">Done</button>
                    <button type="button" name="button" class="btn btn-sm btn-purple-outline pull-right me-1" (click)="toggleDesgEdit()">Cancel</button>


                </div>
            </div>

        </div>

    </form>



</div>