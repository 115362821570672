// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// The other similar file is environment.prod.ts.

export const environment = {
  production: false,
  // apiServerURL: "http://127.0.0.1:3100",
  apiServerURL : 'https://acis.affineanalytics.co.in:3200',
  uiApiEncPwd: 'affEncDec',
  uiApiDecPwd: 'affEncDec',
  locEncDecPwd: 'affEncDec',
  uiApiDecSPwd: '#$SAf%fineYS$%H615&%$#'
};
