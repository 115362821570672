import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class IdeaService {

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,

	) { }

	private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	addIdeasList(model:any) {
		return this.httpClient.post(this.globals.apiServerIP + '/SaveMyIdea/', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getIdeaList(data : any ={}) {
		return this.httpClient.get(this.globals.apiServerIP + '/getMyIdea/',{params:data})

			.pipe(
				map((response: any) => {
				return response[0].data
				}),
				catchError(this.handleError)
			);
	}

	updateIdeasList(model:{}) {
			return this.httpClient.put(this.globals.apiServerIP +'/updateIdea/',model)
			.pipe(
			  map((response: any) => {
			  return response;
			  }),
			  catchError(this.handleError)
		  );
		}

	deleteIdeaList(Id: any){
		return this.httpClient.post(this.globals.apiServerIP + '/deleteIdea/', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}
	getApprovalIdeaList(data : any = {}){
		return this.httpClient.get(this.globals.apiServerIP + '/getApprovalIdea/', {params: data})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}

	updateApprovals(model : any){
		return this.httpClient.put(this.globals.apiServerIP +'/updateIdeaApproval/', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getPublicIdeaData(data : any ={}){
		return this.httpClient.get(this.globals.apiServerIP + '/getPublicIdea/',{params:data})
			.pipe(
				map((response: any) => {
				return response[0].data
				}),
				catchError(this.handleError)
			);
	}

	getCommentHistory(Log_Id:string){
		let params = new HttpParams();
		params = params.append('Log_Id', Log_Id);
		return this.httpClient.get(this.globals.apiServerIP + '/getCommentHistory/',{params:params})
			.pipe(
				map((response: any) => {
				return response[0].data
				}),
				catchError(this.handleError)
			);
	}

	addPublicIdea(model:{}){
		return this.httpClient.post(this.globals.apiServerIP + '/savePublicVoting/', model)
			.pipe(
				map((response: any) => {

					return response;
				}),
				catchError(this.handleError)
			);

	}

	updatePublicIdea(model:{}){
		return this.httpClient.put(this.globals.apiServerIP +'/updatePublicVoting/', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
}

