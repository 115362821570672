import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';


@Injectable()
export class TransformerAwardsService {
	service_url = this.globals.apiBaseURL



	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	) { }


	private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	addNominations(data: any) {
		return this.httpClient.post(this.service_url + '/addNominations', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

	}

	getNominations(data: any) {
		return this.httpClient.post(this.service_url + '/getNominations', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getAwardsDetails(data: any) {
		return this.httpClient.post(this.service_url + '/getAwardsDetails', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	deleteNominations(id: any) {
		return this.httpClient.post(this.service_url + '/deleteNominations', id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	};

	//GET Employee List
	updateNominations(data: any) {
		return this.httpClient.post(this.service_url + '/updateNominations', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateNominationsStatus(data: any) {
		return this.httpClient.post(this.service_url + '/updateNominationsStatus', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getEmployeeDetails(data: any) {
		return this.httpClient.post(this.service_url + '/getEmployeeDetails', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getTransformerSettings() {
		return this.httpClient.get(this.service_url + '/getTransformerSettings')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

	}
	updateTransformerSettingDetails(data: any) {
		return this.httpClient.post(this.service_url + '/updateTransformerSetting', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
	updateTransformerAdmin(data: any) {
		return this.httpClient.post(this.service_url + '/updateTransformerAdmin', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	getAwardsData(data: any) {
		return this.httpClient.post(this.service_url + '/getAwardsData', data)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	}
}
