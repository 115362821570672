import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';

@Injectable()
export class RevenueService {

	private accURL= this.globals.apiServerIP +'/getRevenue/';
	private accURL2= this.globals.apiServerIP +'/getRecognizedRevenue/';
	private accURL3= this.globals.apiServerIP +'/getRevenueInvoices/';
	constructor(
	  private httpClient: HttpClient,
	  private globals: Globals
	){}

	getRevenueReport(reqObj : any) {
		let params = new HttpParams();
		params = params.append('start_date', reqObj.start_date);
		params = params.append('end_date', reqObj.end_date);
		params = params.append('sow_currency',reqObj.sow_currency ? reqObj.sow_currency : 'All');
		reqObj.Account_Id ? params = params.append('account', reqObj.Account_Id) : "";
		reqObj.SOW_Id ? params = params.append('sow', reqObj.SOW_Id) : "";
		reqObj.rev_Id ? params = params.append('SOW_Managed_By',reqObj.rev_Id) : "";
		reqObj.pursued_Id ? params = params.append('sowStartedBy',reqObj.pursued_Id) : "";
		reqObj.currency_period ? params = params.append('currency_period',reqObj.currency_period) : "";

		return this.httpClient.get(this.accURL,{params: params}) 
		.pipe(
		  map((response:any) => {
			 return response[0].data;
		  }),
		  catchError(this.handleError)
		)
	};

	getRecognizedRevenue(reqObj :  any) {
		let params = new HttpParams();
		params = params.append('start_date', reqObj.start_date);
		params = params.append('end_date', reqObj.end_date);
		params = params.append('account', reqObj.Account_Id);
		params = params.append('sow', reqObj.SOW_Id);
		return this.httpClient.get(
			this.accURL2,
			{params: params}
		)
		.pipe(
		  map((response:any) => {
			 return response[0].data;
		  }),
		  catchError(this.handleError)
		)
	};

	getRevenueInvoices(reqObj : any) {
		let params = new HttpParams();
		params = params.append('start_date', reqObj.start_date);
		params = params.append('end_date', reqObj.end_date);
		params = params.append('account', reqObj.Account_Id);
		params = params.append('sow', reqObj.SOW_Id);
		return this.httpClient.get(
			this.accURL3,
			{params: params}
		)
		.pipe(
		  map((response:any) => {
			 return response[0].data;
		  }),
		  catchError(this.handleError)
		)

	};

	saveRecognizedRevenueDetails(model: {}, type:number = 0) {
		return this.httpClient.post(
			this.globals.apiServerIP + '/api/saveRecognizedRevenue?type='+ type,
			model
			)
			.pipe(
			  map((response:any) => {
				 return response;
			  }),
			  catchError(this.handleError)
			)
	}

	saveRevenueInvoiceDetails(model: {}) {
		
		return this.httpClient.post(
			this.globals.apiServerIP + '/api/saveRevenueInvoice',
			model
			)
			.pipe(
			  map((response:any) => {
				 return response;
			  }),
			  catchError(this.handleError)
			)
	}

	private handleError(error: any): Promise<any> {
		console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

}
