import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeIdNameService, CommonDataStreamService } from '../../services';
import { Globals } from '../../global';
import { EmployeeDetailsService } from '../../services/employeedetails/employeedetails.service';
import { utils, write, WorkBook } from 'xlsx';
import { saveAs } from 'file-saver';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	employeeIdNames: any;
	role = 0;
	employeeName: string = '';
	username: string = '';
	employeedetail: string = '';
	sowApprover: boolean = false;
	ifApprover: boolean = false;
	ifNotApprover: boolean = false;
	employeeid: string | undefined;
	loginid: string | undefined;
	dataStream: any;
	notifySowLength: number = 0;
	active_employees: any = [];
	allSows: any = [];
	newSows: any = [];
	updSows: any = [];
	lvl1Sows: any = [];
	lvl2Sows: any = [];
	srcPath: any  = '';
	basicDetails: any = {};

	invReqs: any = [];
	showInvReqs: boolean = false;

	constructor(
		private router: Router,
		public employeeIdNameService: EmployeeIdNameService,
		private globals: Globals,
		public cds: CommonDataStreamService,
		private empDetService: EmployeeDetailsService,
		private clipboard: Clipboard
	) {
		this.role = this.globals.getLoginUserRole();
		this.employeeName = this.globals.getLoginUserFullName();
		this.username = this.globals.getLoginUsername();
	}

	ngOnInit(): void {
		this.allSows = [];
		this.getEmployeeIdNames();
		this.dataStream = this.cds.dataStream;

		this.setProfileImage();
		this.employeeIdNameService.getNotifySOW()
			.subscribe({
				next: response => {
					if (response[0].data) {
						var NotifySOW = response[0].data;
						this.allSows = response[0].data.slice();
						this.notifySowLength = NotifySOW.length;
						this.newSows = NotifySOW.filter(
							(obj: any) => obj.Approval_Status_Id == 1
						);
						this.updSows = NotifySOW.filter(
							(obj: any) =>
								obj.Approval_Status_Id >= 2 && obj.Approval_Status_Id <= 11
						);
						this.lvl1Sows = NotifySOW.filter((obj: any) => {
							if (obj.Approval_Status_Id >= 12 && obj.Approval_Status_Id <= 21) {
								return true;
							}
							else {
								return false;
							}
						});

						this.lvl2Sows = NotifySOW.filter((obj: any) => {
							if (obj.Approval_Status_Id >= 22 && obj.Approval_Status_Id <= 31) {
								return true;
							}
							else {
								return false;
							}
						});

						var accManObj, delManObj;
						this.allSows.forEach((obj: any) => {
							accManObj = this.getEmpDetails(obj.Account_Manager);
							delManObj = this.getEmpDetails(obj.Delivery_Manager);
							if (accManObj) {
								obj.Account_Manager_Name = accManObj.Employee_Name;
								obj.Account_Manager_Email = accManObj.OfficialEmail_Id;
							}
							if (delManObj) {
								obj.Delivery_Manager_Name = delManObj.Employee_Name;
								obj.Delivery_Manager_Email = delManObj.OfficialEmail_Id;
							}
						});
					}
				},
				error: err => {
					alert(err);
					console.error('Error Occured: ', err);
				}
			})


		var validSowApprover = this.dataStream.SOW_Approver.find((obj: any) => obj.Approver_Id == this.username);
		if (validSowApprover) {
			this.sowApprover = true;
			this.ifApprover = true;
			this.ifNotApprover = false;
		} else {
			this.ifApprover = false;
			this.ifNotApprover = true;
		}

		let loginUser = this.globals.getLoginUsername();
		let finManUsers = this.dataStream.financeManagerUsers;
		let invReqs = this.dataStream.invoiceRequests;

		if (
			invReqs &&
			invReqs.length &&
			finManUsers &&
			finManUsers.length &&
			finManUsers.find((usr: any) => {
				return usr.username == loginUser;
			})
		) {
			//console.log('Show inv notifications...');
			this.invReqs = invReqs;
			this.showInvReqs = true;
		} else {
			this.invReqs.length = 0;
			this.showInvReqs = false;
		}
	}

	setProfileImage() {
		this.empDetService.getBasicDetails(this.username)
			.subscribe({

				next: response => {
					this.basicDetails = Object.assign({}, response.data[0]);
					if (this.basicDetails.Photograph != null) {
						var url = this.globals.apiBaseURL + '/static/' + this.basicDetails.Photograph
						this.globals.isFileExist(url)
							.subscribe({
								next: (response1: any) => {
									this.srcPath = this.globals.apiBaseURL + '/static/' + this.basicDetails.Photograph;
								},
								error: (err) => {
									if (this.basicDetails.Gender == 'Male') {
										this.srcPath = this.globals.apiBaseURL + '/static/male.png';
									} else {
										this.srcPath = this.globals.apiBaseURL + '/static/female.png';
									}
								},
							})
					} else {
						if (this.basicDetails.Gender == 'Male') {
							this.srcPath = this.globals.apiBaseURL + '/static/male.png';
						} else {
							this.srcPath = this.globals.apiBaseURL + '/static/female.png';
						}
					}
					//this.srcPath += '?token=' + this.globals.getLoginUserToken();
				},
				error: err => { console.error('Error Occured: ', err) }
			})
	}


	onLoggedout() {
		this.globals.logout();
	}

	getEmployeeIdNames(empDetail = ''): void {
		if (empDetail !== '') {
			this.showProfile({ employeedetail: empDetail });
		}
	}

	showProfile(model: any) {
		var empId = '';

		var validDetail = this.globals.getEmployeeId(model.employeedetail);

		if (validDetail) {
			empId = validDetail;
		} else {
			var reqText = model.employeedetail.toLowerCase(),
				filteredDetail = this.employeeIdNameService.empIdNames.find((emp: any) =>
					emp.Employee_Id.toLowerCase().indexOf(reqText) !== -1 ||
					emp.Employee_Name.toLowerCase().indexOf(reqText) !== -1
				);

			if (filteredDetail) {
				empId = filteredDetail.Employee_Id;
			} else {
				this.employeedetail = '';
				return false;
			}
		}

		switch (this.role) {
			case 1:
				this.redirectTo('/profile/' + empId);
				//this.router.navigate(['/profile', empId]);
				break;
			case 2:
				this.redirectTo('/profile/' + empId);
				//this.router.navigate(['/profile', empId]);
				break;
			case 4:
				this.redirectTo('/employee/' + empId);
				//this.router.navigate(['/employee', empId]);
				break;
			case 8:
				this.redirectTo('/employee/' + empId);
				//this.router.navigate(['/employee', empId]);
				break;
			case 16:
				this.redirectTo('/employee/' + empId);
				//this.router.navigate(['/employee', empId]);
				break;
			default:
				this.redirectTo('/employee/' + empId);
			//this.router.navigate(['/employee', empId]);
		}

		this.employeedetail = '';
		return true;
	}

	redirectTo(uri: string) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate([uri]));
	}

	checkValidInput(inputVal: string) {
		if (inputVal && inputVal.length >= 8 && inputVal.charAt(inputVal.length - 1) === ')') {
			this.showProfile({ employeedetail: inputVal });
		}
	}

	copyToClipboard(item:any) {
		this.clipboard.copy(item);
		// var a:any = null;
		// document.addEventListener('copy', (e: ClipboardEvent) => {
		// 	if (e.clipboardData) {		
		// 		e.clipboardData.setData('text/plain', (item));
		// 		e.preventDefault();
		// 		document.removeEventListener('copy',a);
		// 	}
		// });
		// document.execCommand('copy');
		// alert(`SOW Name "${item}" copied to the clipboard. `)
	  }

	downloadList(): void {
		const ws_name = 'Sheet1';
		const wb: WorkBook = { SheetNames: [], Sheets: {} };

		var filteredData = this.allSows.map((obj: any) => {
			return {
				Account_Name: obj.Account_Name,
				MSA_Name: obj.MSA_Name,
				SOW_Name: obj.SOW_Name,
				SOW_Id: obj.SOW_Id,
				Approval_Status: obj.Approval_Status,
				Account_Manager_Name: obj.Account_Manager_Name,
				Account_Manager_Email: obj.Account_Manager_Email,
				Delivery_Manager_Name: obj.Delivery_Manager_Name,
				Delivery_Manager_Email: obj.Delivery_Manager_Email,
			};
		});
		const ws: any = utils.json_to_sheet(
			this.globals.getDataTypeFormattedForDownload(filteredData)
		);
		wb.SheetNames.push(ws_name);
		wb.Sheets[ws_name] = ws;
		const wbout = write(wb, {
			bookType: 'xlsx',
			bookSST: true,
			type: 'binary',
		});

		var s2ab = function (s: any) {
			const buf = new ArrayBuffer(s.length);
			const view = new Uint8Array(buf);
			for (let i = 0; i !== s.length; ++i) {
				view[i] = s.charCodeAt(i) & 0xff;
			}
			return buf;
		};
		var nowTime = new Date();
		var downloadFileName =
			'SOWs_Pending_For_Approval' +
			nowTime.toString().substr(0, 24).replace(/ /g, '_').replace(/:/g, '_') +
			'.xlsx';

		saveAs(
			new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
			downloadFileName
		);
	}

	getEmpDetails(empId: string): any {
		return this.employeeIdNameService.empIdNames.find(
			(obj: any) => obj.Employee_Id == empId
		);
	}
}
