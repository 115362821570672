import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmpIdNamePipe } from './empIdName.pipe';
import { EmpFullnameIdPipe } from './empFullnameId.pipe';
import { DesignationPipe, NewDesignationPipe,AADesignationPipe,DepartmentPipe,GroupPipe,SubGroupPipe } from './designation.pipe';
import { FileNamePipe } from './fileName.pipe';
import { RatingDescriptionPipe } from './ratingDescription.pipe';
import { CurrencySymbolPipe } from './currencySymbol.pipe';
import { EmpActivePipe } from './emp-active.pipe';

@NgModule({
    imports: [
      CommonModule
    ],
    declarations: [
      EmpIdNamePipe,
      DesignationPipe,
      NewDesignationPipe,
      FileNamePipe,
      RatingDescriptionPipe,
      CurrencySymbolPipe,
      EmpFullnameIdPipe,
      AADesignationPipe,
      DepartmentPipe,
      GroupPipe,
      SubGroupPipe,
      EmpActivePipe
    ],
    exports: [
      EmpIdNamePipe,
      DesignationPipe,
      NewDesignationPipe,
      FileNamePipe,
      RatingDescriptionPipe,
      CurrencySymbolPipe,
      EmpFullnameIdPipe,
      AADesignationPipe,
      DepartmentPipe,
      GroupPipe,
      SubGroupPipe,
      EmpActivePipe
    ]
})
export class SharedPipesModule { }
